<template>
  <div class="valueStock-pages">
    <PageTitle icon="fa fa-line-chart" main="Valor Em Estoque" sub />
  
    <div class="valueStock-pages-tabs">
        <div v-if="listValuesStocks.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>  
        <div v-else >
            <b-card bg-variant="default" header="Informações" header-bg-variant="dark" header-text-variant="white">
                <b-table ref="selectableTable" head-variant="light" outlined hover :items="listValuesStocks" :fields="fields" responsive="sm" id="my-table"
                    striped fixed>
                </b-table> 
            </b-card>            
        </div>
        <b-button v-show="listValuesStocks.length > 0" class="mt-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>         
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"

export default {
    name: 'ValueStock',
    components: { PageTitle },
    data: function() {
      return {
          generatingPDF: false,
          textBtnPrint: 'IMPRIMIR',                 
          listValuesStocks: [],
          fields: [
              { key: 'stockCenter', label: 'Centro de Estoque', sortable: true },
              { key: 'value', label: 'Valor (R$)', sortable: true }
          ]
      }
    },
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'VALOR EM ESTOQUE',
                format: 'A4',
                orientation: 'landscape',
                separate: false,                                
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'stockCenter',
                            exibition: 'Centro de Estoque'
                          },
                          {
                            original: 'value',
                            exibition: 'Valor (R$)'
                          }
                        ],
                        list: this.listValuesStocks
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'
            }).catch(showError)                  
        },        
        async loadStockCenters() {
            const url = `${baseApiUrl}/stockCenter/getValueStock`
            axios.get(url).then(res => {
                this.listValuesStocks = res.data      
            }).catch(showError)                              
        }
    },
    mounted() {
        this.loadStockCenters()  
    }    
}
</script>

<style>

</style>