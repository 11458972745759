<template>
  <div class="user-dropdown">
    <div class="user-button">
      <span class="d-none d-sm-block userName">{{ user.name }}</span>
      <div class="user-dropdown-img">
        <Gravatar :email="user.email" alt="user" />
      </div>
      <i class="fa fa-angle-down"></i>
    </div>
    <div class="user-dropdown-content">
      <router-link to="/admin" v-if="user.admin">
        <i class="fa fa-cogs"></i> Administração
      </router-link>

      <!-- <router-link to="/logScreen" v-if="user.admin">
        <i class="fa fa-th-list"></i> Auditoria
      </router-link> -->
      <a v-b-modal.modal-about> <i class="fa fa-info-circle"></i> Sobre </a>
      <a href @click.prevent="logout"> <i class="fa fa-sign-out"></i> Sair </a>

      <b-modal id="modal-about" title="Informações do Sistema" ok-only>
        <p class="my-4">Versão 1.0.32</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { userKey } from "../../global";
import { mapState } from "vuex";
import Gravatar from "vue-gravatar";
// import axios from "axios";
// import { baseApiUrl, showError } from "@/global";

export default {
  name: "UserDropdown",
  components: { Gravatar },
  computed: mapState(["user"]),
  methods: {
    logout() {
      // let log = {
      //   user: this.user,
      //   table: "auth",
      //   action: "logout",
      //   content: this.user,
      // };

      // axios["post"](`${baseApiUrl}/log`, log).catch(showError);

      localStorage.removeItem(userKey);
      this.$store.commit("setUser", null);
      this.$router.push({ name: "auth" });
    },
  },
};
</script>

<style>
.userName {
  align-self: center;
  align-items: center;
  display: relative;
  justify-items: center;
  justify-self: center;
  justify-content: center;
}

.user-dropdown {
  position: relative;
  height: 100%;
}
.user-button {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 100;
  height: 100%;
  padding: 0px 20px 0px 20px;
}
.user-dropdown:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.user-dropdown-img {
  margin: 0px 10px;
}
.user-dropdown-img > img {
  max-height: 30px;
  border-radius: 5px;
}
.user-dropdown-content {
  position: absolute;
  right: 0px;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.user-dropdown:hover .user-dropdown-content {
  visibility: visible;
  opacity: 1;
}
.user-dropdown-content a {
  text-decoration: none;
  color: #000;
  padding: 10px;
}
.user-dropdown-content a:hover {
  text-decoration: none;
  color: #000;
  background-color: #f9f9f9;
}
</style>