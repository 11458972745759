<template>
  <footer class="footer no-print">
    <span>
      Copyright
      <strong>imSOFTWARE</strong> © 2013-2021
    </span>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  grid-area: footer;
  /* background:linear-gradient(to right, #be93c5, #7bc6cc); */
  background: linear-gradient(to right, #8DD8F8, #0750A4);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 14px;
}

</style>