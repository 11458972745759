<template>
  <div class="stockCenter-pages">
    <PageTitle icon="fa fa-archive" main="Centro de Estoque" sub />
    <div class="account-pages-tabs">        
        <b-button size="md" variant="success" @click="newStockCenter()">
            NOVO
        </b-button>      
        <b-button class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>         
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="stockCenters" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                    <i class="fa fa-pencil"></i>
                </b-button>                     
            </template>
        </b-table>        

        <div v-if="stockCenters.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                   

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
    </div>
  </div> 
</template>

<script>
import PageTitle from "../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'StockCenter',
    components: { PageTitle },
    data: function() {
      return {
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',        
        ftext: '',
        stockCenters: [],
        stockCenterSelected: {},
        currentPage: 1,
        perPage: 10,         
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],      
      }
    },
    computed: {
      rows() {
        return this.stockCenters.length
      }
    },      
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'CENTRO DE CUSTO',
                format: 'A4',
                orientation: 'portrait',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Nome'
                          }
                        ],
                        list: this.stockCenters
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'
            }).catch(showError)                  
        },
        newStockCenter() {
          this.$router.push({name: 'stockCenterPage', params: { },})          
        },
        onRowSelected(itemSelected) {
          let stockCenterSelected = {    
              _id: itemSelected._id,
              name: itemSelected.name
          }              

          this.$router.push({name: 'stockCenterPage', params: { stockCenterSelected },})            
        },       
        loadStockCenters() {
            const url = `${baseApiUrl}/stockCenter`
            axios.get(url).then(res => {
                this.stockCenters = res.data            
            }).catch(showError)                      
        }    
    },
    mounted() {
        this.loadStockCenters()
    }      
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>