<template>
  <div class="removal-pages">
    <PageTitle icon="fa fa-times" main="Saídas Estoque" sub />
    <div class="removal-pages-tabs">        
        <b-button size="md" variant="success" @click="newRemoval()">
            NOVO
        </b-button>      
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="removals" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="info" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Detalhes'">
                    <i class="fa fa-info"></i>
                </b-button>
                <b-button class="ml-2" size="sm" variant="danger" @click="deleteRemoval(row.item)" v-b-popover.hover.top="'Excluir'">
                    <i class="fa fa-trash"></i>
                </b-button>                
            </template>
        </b-table>        

        <div v-if="removals.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                   

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
    </div>
  </div> 
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: 'Removals',
    components: { PageTitle },
    data: function() {
      return {   
        ftext: '',
        removals: [],
        removalSelected: {},
        currentPage: 1,
        perPage: 10,         
        fields: [                 
            { key: 'mechanical', label: 'Mecânico', sortable: true },
            { key: 'date2', label: 'Data', sortable: true },
            { key: 'equipment.name', label: 'Equipamento', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],      
      }
    },
    computed: {
      rows() {
        return this.removals.length
      }
    },      
    methods: {
        newRemoval() {
          this.$router.push({name: 'removalPage', params: { },})          
        },
        onRowSelected(itemSelected) {
          let removalSelected = {    
              _id: itemSelected._id,
              date: itemSelected.date,
              mechanical: itemSelected.mechanical,
              equipment: itemSelected.equipment,
              listProducts: itemSelected.listProducts,
              historic: itemSelected.historic
          }              

          this.$router.push({name: 'removalPage', params: { removalSelected },})            
        },
        deleteRemoval(itemSelected) {
          this.$bvModal.msgBoxConfirm('Deseja remover?', {
              title: 'Confirmação',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Sim',
              cancelTitle: 'Não',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
          }).then(value => {            
              if (value) {
                axios.post(`${baseApiUrl}/removalStock/delete`, itemSelected).then(() => {
                  this.$toasted.global.defaultSuccess()
                  this.loadRemovals()
                }).catch(showError)
              }
          }).catch()
        },
        loadRemovals() {
            const url = `${baseApiUrl}/removalStock`
            axios.get(url).then(res => {
                this.removals = res.data            

                Moment.locale('pt-br')

                for (let r of this.removals) {
                  r.date2 = Moment(r.date).format('L')
                }
            }).catch(showError)                      
        }    
    },
    mounted() {
        this.loadRemovals()
    }      
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>