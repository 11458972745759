<template>
    <div class="costCenter-admin">
        <PageTitle icon="fa fa-list-ol" main="Cadastro de Centro de Custo" sub />                  
        <b-form>            
            <input id="costCenter-id" type="hidden" v-model="costCenter.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="costCenter-name">
                            <b-form-input id="costCenter-name" type="text" 
                                v-model="costCenter.name" required                       
                                placeholder="Informe o Nome do Centro de Custo..." />
                        </b-form-group>         
                    </b-card>
                </b-col>           
            </b-row>
        </b-form>
            
        <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: "CostCenterPage",
    components: {PageTitle},
    data: function() {
      return { 
        costCenter: {},
      }    
    },
    methods: {          
        save() {
            this.costCenter.name = this.costCenter.name.toUpperCase()

            const method = this.costCenter._id ? 'put' : 'post'
            const id = this.costCenter._id ? `/${this.costCenter._id}` : ''        

            axios[method](`${baseApiUrl}/costCenter${id}`, this.costCenter)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                })  
                .catch(showError)
        },  
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.$route.params.costCenterSelected) {
            this.costCenter = this.$route.params.costCenterSelected            
        }
    }
}     
</script>

<style>

</style>