import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/home/Home'
import AdminPage from '../components/admin/AdminPage'
import Auth from '../components/auth/Auth'
import LogScreen from '../components/log/LogScreen'
import { userKey } from '../global'
import Customer from '../components/cadastre/customer/Customer'
import CustomerPage from '../components/cadastre/customer/CustomerPage'
import Equipment from '../components/cadastre/equipments/Equipment'
import EquipmentPage from '../components/cadastre/equipments/EquipmentPage'
import Employee from '../components/cadastre/employee/Employee'
import EmployeePage from '../components/cadastre/employee/EmployeePage'
import Product from '../components/cadastre/product/Product'
import ProductPage from '../components/cadastre/product/ProductPage'
import Provider from '../components/cadastre/provider/Provider'
import ProviderPage from '../components/cadastre/provider/ProviderPage'
import Account from '../components/financial/Account'
import AccountPage from '../components/financial/AccountPage'
import Purchases from '../components/stock/Purchases'
import PurchasePage from '../components/stock/PurchasePage'
import Extract from '../components/financial/Extract'
import ExtractPage from '../components/financial/ExtractPage'
import CostCenter from '../components/financial/CostCenter'
import CostCenterPage from '../components/financial/CostCenterPage'
import StockCenter from '../components/stock/StockCenter'
import StockCenterPage from '../components/stock/StockCenterPage'
import ValueStock from '../components/reports/ValueStock'
import Removals from '../components/stock/Removals'
import RemovalPage from '../components/stock/RemovalPage'
import StockActual from '../components/reports/StockActual'
import TypeEquipment from '../components/cadastre/typeEquipment/TypesEquipment'
import TypeEquipmentPage from '../components/cadastre/typeEquipment/TypesEquipmentPage'
import BillingPerCostCenter from '../components/reports/BillingPerCostCenter'
import IncomeAndExpensesSynthetic from '../components/reports/IncomeAndExpensesSynthetic'
import entranceRemoval from '../components/reports/entranceRemoval'

Vue.use(VueRouter)

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'adminPage',
        path: '/admin',
        component: AdminPage,
        meta: {
            requiresAdmin: true
        }
    },
    {
        name: 'auth',
        path: '/auth',
        component: Auth
    },
    {
        name: 'logScreen',
        path: '/logScreen',
        component: LogScreen
    },
    {
        name: 'customer',
        path: '/customer',
        component: Customer
    },
    {
        name: 'customerPage',
        path: '/customerPage',
        component: CustomerPage
    },
    {
        name: 'equipment',
        path: '/equipment',
        component: Equipment
    },
    {
        name: 'equipmentPage',
        path: '/equipmentPage',
        component: EquipmentPage
    },
    {
        name: 'employee',
        path: '/employee',
        component: Employee
    },
    {
        name: 'employeePage',
        path: '/employeePage',
        component: EmployeePage
    },
    {
        name: 'product',
        path: '/product',
        component: Product
    },
    {
        name: 'productPage',
        path: '/productPage',
        component: ProductPage
    },
    {
        name: 'provider',
        path: '/provider',
        component: Provider
    },
    {
        name: 'providerPage',
        path: '/providerPage',
        component: ProviderPage
    },
    {
        name: 'account',
        path: '/account',
        component: Account
    },
    {
        name: 'accountPage',
        path: '/accountPage',
        component: AccountPage
    },
    {
        name: 'purchases',
        path: '/purchases',
        component: Purchases
    },
    {
        name: 'purchasePage',
        path: '/purchasePage',
        component: PurchasePage
    },
    {
        name: 'extract',
        path: '/extract',
        component: Extract
    },
    {
        name: 'extractPage',
        path: '/extractPage',
        component: ExtractPage
    },
    {
        name: 'costCenter',
        path: '/costCenter',
        component: CostCenter
    },
    {
        name: 'costCenterPage',
        path: '/costCenterPage',
        component: CostCenterPage
    },
    {
        name: 'stockCenter',
        path: '/stockCenter',
        component: StockCenter
    },
    {
        name: 'stockCenterPage',
        path: '/stockCenterPage',
        component: StockCenterPage
    },
    {
        name: 'valueStock',
        path: '/valueStock',
        component: ValueStock
    },
    {
        name: 'removal',
        path: '/removal',
        component: Removals
    },
    {
        name: 'removalPage',
        path: '/removalPage',
        component: RemovalPage
    },
    {
        name: 'stockActual',
        path: '/stockActual',
        component: StockActual
    },
    {
        name: 'typeEquipment',
        path: '/typeEquipment',
        component: TypeEquipment
    },
    {
        name: 'typeEquipmentPage',
        path: '/typeEquipmentPage',
        component: TypeEquipmentPage
    },
    {
        name: 'billingPerCostCenter',
        path: '/billingPerCostCenter',
        component: BillingPerCostCenter
    },
    {
        name: 'incomeAndExpensesSynthetic',
        path: '/incomeAndExpensesSynthetic',
        component: IncomeAndExpensesSynthetic
    }, 
    {
        name: 'entranceRemoval',
        path: '/entranceRemoval',
        component: entranceRemoval
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    const json = localStorage.getItem(userKey)

    if (to.matched.some(record => record.meta.requiresAdmin)) {
        const user = JSON.parse(json)
        user && user.admin ? next() : next({ path: '/' })
    } else {
        next()
    }
})

export default router