<template>
    <div class="employee-admin">
        <PageTitle icon="fa fa-user" main="Cadastro de Funcionários" sub />                  
        <b-form>            
            <input id="employee-id" type="hidden" v-model="employee.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="employee-name">
                            <b-form-input id="employee-name" type="text" 
                                v-model="employee.name" required                       
                                placeholder="Informe o Nome do Funcionário..." />
                        </b-form-group>         
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CPF:" label-for="employee-cpf">
                                    <b-form-input id="employee-cpf" type="number" 
                                        v-model="employee.cpf" required
                                        placeholder="Informe o CPF do Funcionário..." />
                                </b-form-group> 
                                <b-form-group label="Data Nascimento:" label-for="employee-birthday">
                                    <b-form-input id="employee-birthday" type="date"
                                        v-model="employee.birthday" required
                                        placeholder="Informe a Data de Nascimento do Funcionário..." />
                                </b-form-group>         
                                <b-form-group label="E-mail:" label-for="employee-email">
                                    <b-form-input id="employee-email" type="text"
                                        v-model="employee.email" 
                                        placeholder="Informe o E-mail do Funcionário..." />
                                </b-form-group>                                     
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label="RG:" label-for="employee-rg">
                                    <b-form-input id="employee-rg" type="number"
                                        v-model="employee.rg" 
                                        placeholder="Confirme o RG do Funcionário..." />
                                </b-form-group>
                                <b-form-group label="Sexo:" label-for="employee-gender">
                                    <b-form-select v-model="employee.gender" :options="genders"></b-form-select>
                                </b-form-group>        
                                <b-form-group label="Fone:" label-for="employee-phone">
                                    <b-form-input id="employee-phone" type="number"
                                        v-model="employee.phone" 
                                        placeholder="Informe o Fone do Funcionário..." />
                                </b-form-group>                                 
                            </b-col>        
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group label="Cargo:" label-for="employee-occupation">
                                    <b-form-select v-model="employee.occupation" :options="occupations" required></b-form-select>
                                </b-form-group>                                 
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group label="Senha Mobile:" label-for="employee-passwordMob">
                                    <b-form-input id="employee-passwordMob" 
                                        v-model="employee.passwordMob" 
                                        placeholder="Informe a Senha do App..." />
                                </b-form-group>         
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group class="mt-3" label="Imagem:" label-for="employee-image">
                                    <b-form-file v-model="image" placeholder="Selecione um imagem" class="w-auto flex-grow-1"></b-form-file>
                                </b-form-group>
                            </b-col>   
                            <b-col md="6" sm="6" class="mt-5">
                                <b-button v-if="employee.image" variant="danger" class="ml-3" @click="clearImage">Limpar Imagem</b-button>
                            </b-col>   
                        </b-row>               
                        <b-row>
                            <b-col md="6" sm="6">
                                <div>
                                    <b-img id="imgEmployee" :src="imageSrc" class="mb-3" fluid block rounded width="200" height="200" thumbnail></b-img> 
                                </div>
                            </b-col>                                                                
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Endereço" header-bg-variant="dark" header-text-variant="white">
                        <b-row>    
                            <b-col md="5" sm="12"> 
                                <b-form-input type="text" id="employee-cep" placeholder="CEP..." v-model="address.cep"></b-form-input>
                            </b-col>
                            <b-col md="1" sm="12"> 
                                <b-button @click="searchCEP"><i class="fa fa-search-plus"></i></b-button>
                            </b-col>                            
                        </b-row>
                        <b-row>
                            <b-col md="10" sm="12"> 
                                <b-form-group label="Logradouro:" label-for="employee-street" class="mt-3">
                                    <b-form-input id="employee-street" placeholder="Logradouro..." v-model="address.street"></b-form-input>
                                </b-form-group>
                            </b-col>                         
                            <b-col md="2" sm="12"> 
                                <b-form-group label="Número:" label-for="employee-number" class="mt-3">
                                    <b-form-input id="employee-number" placeholder="Bairro..." v-model="address.number"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Bairro:" label-for="employee-neighborhood">
                                    <b-form-input id="employee-neighborhood" placeholder="Bairro..." v-model="address.neighborhood"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Município:" label-for="employee-city">
                                    <b-form-input id="employee-city" placeholder="Município..." v-model="address.city"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" sm="12"> 
                                <b-form-group label="Complemento:" label-for="employee-complement">
                                    <b-form-input id="employee-complement" placeholder="Complemento..." v-model="address.complement"></b-form-input>
                                </b-form-group>
                            </b-col>           
                            <b-col md="4" sm="12">                    
                                <b-form-group label="UF:" label-for="employee-state">
                                    <b-form-select v-model="address.state" :options="states"></b-form-select>
                                </b-form-group>               
                            </b-col>                                              
                        </b-row>       
                    </b-card>   
                </b-col>                     
            </b-row>
        </b-form>
            
        <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import States from '../../default/states'

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
    name: "EmployeePage",
    components: {PageTitle},
    data: function() {
      return { 
        image: null,
        imageSrc: null,          
        employee: {},
        address: {},
        genders: [
            {value: 1, text: "Masculino"},
            {value: 2, text: "Feminino"}
        ],
        occupations: [
            {value: 1, text: "Diretor"},
            {value: 2, text: "Gerente Geral"},
            {value: 3, text: "Gerente Ponto"},
            {value: 4, text: "Atendente"},
            {value: 5, text: "Mecânico"}
        ],        
        states: States.states,    
      }    
    },
    computed: {
        hasImage() {
            return !!this.image;
        }
    },    
    watch: {
        image(newValue, oldValue) {
        if (newValue !== oldValue) {
            if (newValue) {
            base64Encode(newValue)
                .then(value => {
                this.imageSrc = value;                
                })
                .catch(() => {
                this.imageSrc = null;
                });
            } else {
            this.imageSrc = null;
            }            
        }
        }
    },    
    methods: {          
        clearImage() {
            this.image = null
            this.imageSrc = null
            this.employee.image = null
        },
        save() {
            this.employee.name = this.employee.name.toUpperCase()

            this.employee.image = this.imageSrc
            this.employee.salary = 0

            const method = this.employee._id ? 'put' : 'post'
            const id = this.employee._id ? `/${this.employee._id}` : ''        

            this.employee.address = {
                cep: this.address.cep,
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city:  this.address.city,
                complement: this.address.complement,
                state: this.address.state
            }

            axios[method](`${baseApiUrl}/employee${id}`, this.employee)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                })  
                .catch(showError)
        },  
        searchCEP() {     
            this.$viaCep.buscarCep(this.address.cep).then((obj) => {
                this.address = {
                    cep: obj.cep,
                    street: obj.logradouro,
                    neighborhood: obj.bairro,
                    city: obj.localidade,                    
                }                
            });
        },        
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.$route.params.employeeSelected) {
            this.employee = this.$route.params.employeeSelected            

            this.address = {
                cep: this.employee.address.cep,
                street: this.employee.address.street,
                number: this.employee.address.number,
                neighborhood: this.employee.address.neighborhood,
                city:  this.employee.address.city,
                complement: this.employee.address.complement,
                state: this.employee.address.state
            }

            if (this.employee.image != null) {
                this.imageSrc = this.employee.image
            }
        }
    }
}     
</script>

<style>

</style>