<template>
    <div class="stockCenter-admin">
        <PageTitle icon="fa fa-archive" main="Cadastro de Centro de Estoque" sub />                  
        <b-form>            
            <input id="stockCenter-id" type="hidden" v-model="stockCenter.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="stockCenter-name">
                            <b-form-input id="stockCenter-name" type="text" 
                                v-model="stockCenter.name" required                       
                                placeholder="Informe o Nome do Centro de Custo..." />
                        </b-form-group>         
                    </b-card>
                </b-col>           
            </b-row>
        </b-form>
            
        <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: "CostCenterPage",
    components: {PageTitle},
    data: function() {
      return { 
        stockCenter: {},
      }    
    },
    methods: {          
        save() {
            this.stockCenter.name = this.stockCenter.name.toUpperCase()

            const method = this.stockCenter._id ? 'put' : 'post'
            const id = this.stockCenter._id ? `/${this.stockCenter._id}` : ''

            if (!this.stockCenter.listProducts) {
                this.stockCenter.listProducts = []
            }

            axios[method](`${baseApiUrl}/stockCenter${id}`, this.stockCenter)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                })  
                .catch(showError)
        },  
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.$route.params.stockCenterSelected) {
            this.stockCenter = this.$route.params.stockCenterSelected            
        }
    }
}     
</script>

<style>

</style>