import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMenuVisible: false,
        isTitleVisible: false,
        user: null,
        resultLookup: null,
    },
    mutations: {
        toggleTitle(state, isVisible) {
            if (!state.user) {
                state.isTitleVisible = false
                return
            }

            if (isVisible === undefined) {
                state.isTitleVisible = !state.isTitleVisible
            } else {
                state.isTitleVisible = isVisible
            }
        },
        toggleMenu(state, isVisible) {
            if (!state.user) {
                state.isMenuVisible = false
                return
            }

            if (isVisible === undefined) {
                state.isMenuVisible = !state.isMenuVisible
            } else {
                state.isMenuVisible = isVisible
            }
        },
        setUser(state, user) {
            state.user = user

            if (user) {
                axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
                state.isMenuVisible = true
                state.isTitleVisible = true
            } else {
                delete axios.defaults.headers.common['Authorization']
                state.isMenuVisible = false
                state.isTitleVisible = false
            }
        },
        setResultLookup(state, resultLookup) {
            state.resultLookup = resultLookup
        }
    }
})