<template>
  <div class="equipment-pages">
    <PageTitle icon="fa fa-bicycle" main="Equipamentos" sub />
    <div class="equipment-pages-tabs">
        <b-button size="md" variant="success" @click="newEquipment()">
            NOVO
        </b-button>      
        <b-button class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>         
        <hr>         
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table head-variant="light" outlined hover :fields="fields" responsive="sm" id="my-table" :items="equipments"
                 :per-page="perPage" :current-page="currentPage" striped :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                    <i class="fa fa-pencil"></i>
                </b-button>
                <b-button size="sm" variant="danger" @click="removeEquipment(row.item)" v-b-popover.hover.top="'Excluir'" class="ml-2">
                    <i class="fa fa-trash"></i>
                </b-button>                   
            </template>                 
        </b-table>        

        <div v-if="equipments.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>          

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'Equipments',
    components: { PageTitle },
    data: function() {
      return {
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',        
        ftext: '',
        equipments: [],
        currentPage: 1,
        perPage: 10,
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'description', label: 'Descrição', sortable: true },
            { key: 'number', label: 'Número', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],          
      }
    },
    computed: {
      rows() {
        return this.equipments.length
      }
    },
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'EQUIPAMENTOS',
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Nome'
                          },
                          {
                            original: 'description',
                            exibition: 'Descrição'
                          },
                          {
                            original: 'number',
                            exibition: 'Número'
                          },
                        ],
                        list: this.equipments
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'    
            }).catch(showError)       
        },
        newEquipment() {
          this.$router.push({name: 'equipmentPage', params: { },})          
        },
        onRowSelected(itemSelected) {
          let equipmentSelected = {    
              _id: itemSelected._id,
              name: itemSelected.name,
              description: itemSelected.description,
              number: itemSelected.number,
              tablePrices: itemSelected.tablePrices
          }              

          this.$router.push({name: 'equipmentPage', params: { equipmentSelected },})            
        },               
        loadEquipments() {
            const url = `${baseApiUrl}/equipment`
            axios.get(url).then(res => {  
                this.equipments = res.data
            }).catch(showError)                      
        },    
        removeEquipment(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover o equipamento?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  const id = itemSelected._id
                  axios.delete(`${baseApiUrl}/equipment/${id}`).then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadEquipments()
                  }).catch(showError)                
                }                
            }).catch()   
        }
    },
    mounted() {
        this.loadEquipments()
    }      
};
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>