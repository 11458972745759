<template>
  <div class="equipment-admin">
    <PageTitle icon="fa fa-bicycle" main="Cadastro de Equipamentos" sub />
    <b-form>
      <input id="equipment-id" type="hidden" v-model="equipment.id" />
      <b-row>
        <b-col md="6" sm="12">
          <b-card
            bg-variant="default"
            header="Informações Gerais"
            header-bg-variant="dark"
            header-text-variant="white"
          >
            <b-row>
              <b-col md="9" sm="12">
                <b-form-group label="Nome:" label-for="equipment-name">
                  <b-form-input
                    id="equipment-name"
                    type="text"
                    v-model="equipment.name"
                    required
                    placeholder="Informe o Nome do Equipamento..."
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12">
                <b-form-group label="Número:" label-for="equipment-number">
                  <b-form-input
                    id="equipment-number"
                    type="number"
                    v-model="equipment.number"
                    placeholder="Informe o Número do Equipamento..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" sm="12">
                <b-form-group
                  label="Descrição:"
                  label-for="equipment-description"
                >
                  <b-textarea
                    id="equipment-description"
                    v-model="equipment.description"
                    placeholder="Descrição do Equipamento..."
                  ></b-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col md="6" sm="12">
          <b-card
            class="mt-3"
            bg-variant="default"
            header="Tabela de Preços"
            header-bg-variant="dark"
            header-text-variant="white"
          >
            <b-row>
              <b-button
                v-b-modal.modal-prevent-closing
                variant="success"
                v-b-popover.hover.top="'Adicionar'"
                class="ml-2"
              >
                <i class="fa fa-plus"></i>
              </b-button>
              <b-modal
                id="modal-prevent-closing"
                ref="modal"
                title="Tabela de Preços"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group
                    label="Centro de Custo:"
                    label-for="equipment-costCenter"
                  >
                    <b-form-select
                      id="equipment-costCenter"
                      v-model="costCenter"
                      :options="costCenters"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Quantidade:"
                    label-for="count-input"
                    invalid-feedback="Informe a Quantidade"
                    :state="countState"
                  >
                    <b-form-input
                      id="count-input"
                      v-model="count"
                      :state="countState"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Valor (R$):"
                    label-for="value-input"
                    invalid-feedback="Valor (R$)"
                    :state="valueState"
                  >
                    <b-form-input
                      id="value-input"
                      v-model="value"
                      :state="valueState"
                      required
                    ></b-form-input>
                  </b-form-group>
                </form>
              </b-modal>
            </b-row>
            <b-row>
              <b-table
                head-variant="light"
                outlined
                hover
                :fields="fields"
                id="my-table"
                :items="tablePrices"
                class="mt-3 ml-2 mr-2"
                striped
              >
                <template v-slot:cell(actions)="row">
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="removePrice(row.item)"
                    v-b-popover.hover.top="'Excluir'"
                    class="ml-2"
                  >
                    <i class="fa fa-trash"></i>
                  </b-button>
                </template>
              </b-table>
            </b-row>
          </b-card>
        </b-col>
      </b-row> -->
    </b-form>

    <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>
    <b-button variant="danger" @click="cancel" class="mt-3 ml-2"
      >CANCELAR</b-button
    >
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from "@/global";
import axios from "axios";

export default {
  name: "EquipmentPage",
  components: { PageTitle },
  data: function () {
    return {
      equipment: {},
      tablePrices: [],
      countState: null,
      count: null,
      value: null,
      valueState: null,
      costCenterState: null,
      costCenter: null,
      costCenters: [],
      fields: [
        { key: "costCenter.name", label: "Centro de Custo", sortable: true },
        { key: "count", label: "Quantidade (min)", sortable: true },
        { key: "value", label: "Valor (R$)", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      fieldsPrices: [
        { key: "costCenter.name", label: "Centro de Custo", sortable: true },
        { key: "count", label: "Quantidade (min)", sortable: true },
        { key: "value", label: "Valor (R$)", sortable: true },
      ],
    };
  },
  methods: {
    save() {
      this.equipment.name = this.equipment.name.toUpperCase();
      this.equipment.tablePrices = this.tablePrices;

      const method = this.equipment._id ? "put" : "post";
      const id = this.equipment._id ? `/${this.equipment._id}` : "";

      axios[method](`${baseApiUrl}/equipment${id}`, this.equipment)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.go(-1);
        })
        .catch(showError);
    },
    removePrice(itemSelected) {
      let i = 0;

      this.tablePrices.forEach((tp) => {
        if (
          tp.value == itemSelected.value &&
          tp.costCenter == itemSelected.costCenter
        ) {
          this.tablePrices.splice(i, 1);
        }

        i += 1;
      });
    },
    loadCostCenters() {
      const url = `${baseApiUrl}/costCenter`;
      axios
        .get(url)
        .then((res) => {
          for (let d of res.data) {
            this.costCenters.push({
              value: { _id: d._id, name: d.name },
              text: d.name,
            });
          }
        })
        .catch(showError);
    },
    cancel() {
      this.$router.go(-1);
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      this.countState = valid;

      valid = this.$refs.form.checkValidity();
      this.valueState = valid;

      valid = this.$refs.form.checkValidity();
      this.costCenterState = valid;

      if (this.countState && this.valueState && this.costCenterState) {
        valid = true;
      } else {
        valid = false;
      }

      return valid;
    },
    resetModal() {
      this.count = "";
      this.countState = null;
      this.value = "";
      this.valueState = null;
      this.costCenter = "";
      this.costCenterState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.tablePrices.push({
        costCenter: this.costCenter,
        count: this.count,
        value: parseFloat(this.value).toFixed(2),
      });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
  mounted() {
    this.loadCostCenters();

    if (this.$route.params.equipmentSelected) {
      this.equipment = this.$route.params.equipmentSelected;
      this.tablePrices = this.equipment.tablePrices;
    }
  },
};
</script>

<style>
</style>