<template>
  <div class="typesEquipment-admin">
    <PageTitle icon="fa fa-bicycle" main="Cadastro de Tipos de Equipamentos" sub />
    <b-form>
      <input id="typeEquipment-id" type="hidden" v-model="typeEquipment.id" />
      <b-row>
        <b-col md="8" sm="12">
          <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
            <b-row>
              <b-col md="9" sm="12">
                <b-form-group label="Nome:" label-for="typeEquipment-name">
                  <b-form-input id="typeEquipment-name" type="text" v-model="typeEquipment.name" required placeholder="Informe o Nome do Tipo de Equipamento..."/>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12">
                <b-form-group label="Valor Adicional Por Minutos:" label-for="typeEquipment-valueAditionalPerMinute">
                  <b-form-input id="typeEquipment-valueAditionalPerMinute" type="number" v-model="typeEquipment.valueAditionalPerMinute" required 
                    placeholder="Valor Adicional..."/>
                </b-form-group>
              </b-col>                            
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <b-card
            class="mt-3"
            bg-variant="default"
            header="Tabela de Preços"
            header-bg-variant="dark"
            header-text-variant="white"
          >
            <b-row>
              <b-button @click="setAction('insert', null)" v-b-modal.modal-prevent-closing variant="success" v-b-popover.hover.top="'Adicionar'" class="ml-2">
                <i class="fa fa-plus"></i>
              </b-button>
              <b-modal id="modal-prevent-closing" ref="modal" title="Tabela de Preços" @hidden="resetModal" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group label="Centro de Custo:" label-for="equipment-costCenter">
                    <b-form-select
                      id="equipment-costCenter"
                      v-model="costCenter"
                      :options="costCenters"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Quantidade:"
                    label-for="count-input"
                    invalid-feedback="Informe a Quantidade"
                    :state="countState"
                  >
                    <b-form-input
                      id="count-input"
                      v-model="count"
                      type="number"
                      :state="countState"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Valor (R$):"
                    label-for="value-input"
                    invalid-feedback="Valor (R$)"
                    :state="valueState"
                  >
                    <b-form-input
                      id="value-input"
                      v-model="value"
                      type="number"
                      :state="valueState"
                      required
                    ></b-form-input>
                  </b-form-group>
                </form>
              </b-modal>
            </b-row>
            <b-row>
              <b-table head-variant="light" outlined hover :fields="fields" id="my-table" :items="tablePrices" class="mt-3 ml-2 mr-2" striped>
                <template v-slot:cell(actions)="row">
                  <b-button @click="setAction('update', row)" v-b-modal.modal-prevent-closing size="sm" variant="warning" v-b-popover.hover.top="'Alterar'" class="ml-2">
                    <i class="fa fa-pencil"></i>
                  </b-button>

                  <b-button size="sm" variant="danger" @click="removePrice(row.item)" v-b-popover.hover.top="'Excluir'" class="ml-2">
                    <i class="fa fa-trash"></i>
                  </b-button>
                </template>
              </b-table>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>
    <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from "@/global";
import axios from "axios";

export default {
  name: "TypeEquipmentPage",
  components: { PageTitle },
  data: function () {
    return {
      typeEquipment: {},
      tablePrices: [],
      countState: null,
      count: null,
      value: null,
      valueState: null,
      costCenterState: null,
      costCenter: null,
      costCenters: [],
      action: '',
      indexUpdate: 0,
      fields: [
        { key: "costCenter.name", label: "Centro de Custo", sortable: true },
        { key: "count", label: "Quantidade (min)", sortable: true },
        { key: "value", label: "Valor (R$)", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      fieldsPrices: [
        { key: "costCenter.name", label: "Centro de Custo", sortable: true },
        { key: "count", label: "Quantidade (min)", sortable: true },
        { key: "value", label: "Valor (R$)", sortable: true },
      ],
    };
  },
  methods: {
    save() {
      this.typeEquipment.name = this.typeEquipment.name.toUpperCase();
      this.typeEquipment.tablePrices = this.tablePrices;

      const method = this.typeEquipment._id ? "put" : "post";
      const id = this.typeEquipment._id ? `/${this.typeEquipment._id}` : "";

      axios[method](`${baseApiUrl}/typeEquipment${id}`, this.typeEquipment)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.go(-1);
        })
        .catch(showError);
    },
    setAction(action, data) {
      this.action = action 

      if (action == 'update') {
        this.indexUpdate = data.index

        this.costCenter = data.item.costCenter
        this.count = data.item.count
        this.value = data.item.value
      }
    },
    removePrice(itemSelected) {
      let i = 0;

      this.tablePrices.forEach((tp) => {
        if (
          tp.value == itemSelected.value &&
          tp.costCenter == itemSelected.costCenter
        ) {
          this.tablePrices.splice(i, 1);
        }

        i += 1;
      });
    },
    loadCostCenters() {
      const url = `${baseApiUrl}/costCenter`;
      axios
        .get(url)
        .then((res) => {
          for (let d of res.data) {
            this.costCenters.push({
              value: { _id: d._id, name: d.name },
              text: d.name,
            });
          }
        })
        .catch(showError);
    },
    cancel() {
      this.$router.go(-1);
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      this.countState = valid;

      valid = this.$refs.form.checkValidity();
      this.valueState = valid;

      valid = this.$refs.form.checkValidity();
      this.costCenterState = valid;

      if (this.countState && this.valueState && this.costCenterState) {
        valid = true;
      } else {
        valid = false;
      }

      return valid;
    },
    resetModal() {
      this.count = "";
      this.countState = null;
      this.value = "";
      this.valueState = null;
      this.costCenter = "";
      this.costCenterState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.action == 'insert') {
        this.tablePrices.push({
          costCenter: this.costCenter,
          count: this.count,
          value: parseFloat(this.value).toFixed(2),
        });
      } else if (this.action == 'update') {
        this.tablePrices[this.indexUpdate].costCenter = this.costCenter 
        this.tablePrices[this.indexUpdate].count = this.count
        this.tablePrices[this.indexUpdate].value = this.value
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
  mounted() {
    this.loadCostCenters();

    if (this.$route.params.typeEquipmentSelected) {
      this.typeEquipment = this.$route.params.typeEquipmentSelected;
      this.tablePrices = this.typeEquipment.tablePrices;
    }
  },
};
</script>

<style>
</style>