<template>
  <div class="removal-admin">
    <PageTitle icon="fa fa-times" main="Saída de Peças" sub />
    <b-form>
      <input id="removal-id" type="hidden" v-model="removal.id" />
      <b-row>
        <b-col md="12" sm="12">
          <b-card bg-variant="default" header="Dados Gerais" header-bg-variant="dark" header-text-variant="white">
            <b-row>         
              <b-col md="3" sm="8">
                <b-form-group label="Data:" label-for="removal-date">
                  <b-form-input id="removal-date" type="date" v-model="removal.date"/>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12">
                <b-form-group label="Mecânico:" label-for="removal-mechanical">
                  <b-form-input id="removal-mechanical" type="text" v-model="mechanical.name" disabled/>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="6" class="align-self-center">
                    <b-button class="mt-3" v-if="!removal._id" v-b-modal.modal-prevent-closing @click="setModal('mechanical')"><i class="fa fa-search"></i></b-button>
                    <Lookup v-if="modal === 'mechanical'" ref="LookupMechanical" title="Mecânico" :list="mechanicals" :fields="fieldsMec"
                        :invalidFeedback="invalidFeedback" resultTable="mechanical" @event="addMechanical">
                    </Lookup>                
              </b-col>
              <b-col md="3" sm="12">
                <b-form-group label="Equipamento:" label-for="removal-equipment">
                  <b-form-input id="removal-equipment" type="text" v-model="equipment.name" disabled/>
                </b-form-group>
              </b-col>              
              <b-col md="1" sm="6" class="align-self-center">
                <b-button class="mt-3" v-if="!removal._id" v-b-modal.modal-prevent-closing @click="setModal('equipment')"><i class="fa fa-search"></i></b-button>
                    <Lookup v-if="modal === 'equipment'" ref="LookupEquipment" title="Equipamentos" :list="equipments" :fields="fieldsEquipment"
                        :invalidFeedback="invalidFeedback" resultTable="equipment" @event="addEquipment">
                    </Lookup>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8" sm="12">
                <b-textarea id="removal-historic" placeholder="Histórico..." v-model="removal.historic"></b-textarea>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
          <b-card bg-variant="default" header="Peças" header-bg-variant="dark" header-text-variant="white">
            <b-row>
              <b-col md="1" sm="6" class="align-self-center">
                <b-button v-if="!removal._id" variant="success" v-b-modal.modal-prevent-closing @click="setModal('product')" v-b-popover.hover.top="'Adicionar'">
                    <i class="fa fa-plus"></i></b-button>
                    <Lookup v-if="modal === 'product'" ref="LookupProduct" title="Peças" :list="products" :fields="fieldsProductsLookup"
                        :invalidFeedback="invalidFeedback" resultTable="productRemoval" @event="addProduct" :stockCenters='stockCenters'>
                    </Lookup>
              </b-col>
              <hr>
            </b-row>
            <b-row>
                <b-table ref="selectableTable" head-variant="light" outlined hover :items="productsSelected" :fields="fieldsProducts" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext" class="mt-3 ml-3 mr-3">
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Excluir'">
                            <i class="fa fa-pencil"></i>
                        </b-button>                     
                    </template>
                </b-table>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-button v-if="!removal._id" variant="success" @click="save" class="mt-3">SALVAR</b-button>
    <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>
  </div>
</template>
<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"
import Lookup from "../default/Lookup"

export default {
  name: "RemovalPage",
  components: { PageTitle, Lookup },
  data: function () {
    return {
        removal: {},
        equipment: {},
        equipments: [],
        products: [],
        productsSelected: [],
        stockCenters: [],
        stockCenter: null,
        mechanical: {},
        mechanicals: [],
        mechanicalSelected: null,
        modal: null,
        currentPage: 1,
        perPage: 10,   
        ftext: '',        
        invalidFeedback: "",
        fieldsProducts: [
            { key: "name", label: "Nome", sortable: true },
            { key: "unit", label: "Unidade Medida", sortable: true },
            { key: "count", label: "Quantidade", sortable: true },
            { key: "stockCenter.name", label: "Centro de Estoque", sortable: true },
        ],
        fieldsProductsLookup: [
            { key: "name", label: "Nome", sortable: true },
            { key: "unit", label: "Unidade Medida", sortable: true },
            { key: "count", label: "Quantidade", sortable: true },
            { key: "stockCenter", label: "Centro de Estoque", sortable: true },
        ],        
        fieldsEquipment: [
            { key: "name", label: "Nome", sortable: true },
            { key: "number", label: "Número", sortable: true }
        ],
        fieldsMec: [
            { key: "name", label: "Nome", sortable: true }
        ]
    }
  },
  methods: {
    save() {
        this.removal.mechanical = this.mechanical.name.toUpperCase()
        this.removal.products = this.productsSelected
        this.removal.equipment = this.equipment
        this.removal.listProducts = this.productsSelected

        const method = this.removal._id ? 'put' : 'post'
        const id = this.removal._id ? `/${this.removal._id}` : ''        

        axios[method](`${baseApiUrl}/removalStock${id}`, this.removal)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            })  
            .catch(showError)
    },
    cancel() {
        this.$router.go(-1)
    },
    addProduct(payload) {
        this.productsSelected.push({
            _id: payload.productSelected._id,
            name: payload.productSelected.name,
            unit: payload.productSelected.unit,
            count: Number(payload.productSelected.count).toFixed(2),
            stockCenter: payload.productSelected.stockCenter
        })
    },
    addEquipment(payload) {
        this.equipment = payload.equipmentSelected
    },    
    addMechanical(payload) {
        this.mechanical = payload.mechanicalSelected
    },
    loadProducts() {
        const url = `${baseApiUrl}/product`
        axios.get(url).then(res => {
            this.products = res.data            
        }).catch(showError)                              
    },  
    loadStockCenters() {
        const url = `${baseApiUrl}/stockCenter`
        axios.get(url).then(res => {
            for (let d of res.data) {
                this.stockCenters.push({
                    value: { _id: d._id, name: d.name, listProducts: d.listProducts }, text: d.name
                })
            }
        }).catch(showError)                              
    },
    loadEquipments() {
        const url = `${baseApiUrl}/equipment`
        axios.get(url).then(res => {
            this.equipments = res.data            
        }).catch(showError)   
    },
    loadMechanicals() {
        const url = `${baseApiUrl}/employee/getMechanics`
        axios.get(url).then(res => {
             this.mechanicals = res.data            
        }).catch(showError)   
    },    
    setModal(param) {
        this.modal = param
    }            
  },
  mounted() {
      this.loadProducts()
      this.loadEquipments()
      this.loadStockCenters()
      this.loadMechanicals()

      if (this.$route.params.removalSelected) {        
        this.removal = this.$route.params.removalSelected
        this.equipment = this.removal.equipment
        this.productsSelected = this.removal.listProducts
        this.mechanical.name = this.removal.mechanical

        document.getElementById('removal-date').disabled = true
        document.getElementById('removal-mechanicals').disabled = true            
        document.getElementById('removal-equipment').disabled = true
        document.getElementById('removal-historic').disabled = true                
      }     
  }
}
</script>

<style>
</style>