export default {
    treeMain: [
        {
            id: 1, name: "Cadastros", state: { checked: false }, children:
                [
                    { id: 2, name: "Clientes", state: { checked: false } },
                    { id: 3, name: "Equipamentos", state: { checked: false } },
                    { id: 6, name: "Fornecedores", state: { checked: false } },
                    { id: 4, name: "Funcionários", state: { checked: false } },
                    { id: 5, name: "Peças", state: { checked: false } },
                    { id: 19, name: "Tipos de Equipamentos", state: { checked: false } }
                ]
        },
        {
            id: 7, name: "Estoque", state: { checked: false }, children:
                [
                    { id: 14, name: "Centro de Estoque", state: { checked: false } },
                    { id: 9, name: "Compras", state: { checked: false } },
                    { id: 15, name: "Saídas", state: { checked: false } }
                ]
        },
        {
            id: 8, name: "Financeiro", state: { checked: false }, children:
                [
                    { id: 10, name: "Contas Financeiras", state: { checked: false } },
                    { id: 13, name: "Centro de Custo", state: { checked: false } },
                    { id: 11, name: "Caixas", state: { checked: false } },
                    { id: 12, name: "Lançamentos", state: { checked: false } }
                ]
        },
        {
            id: 16, name: "Relatórios", state: { checked: false }, children:
                [
                    // { id: 18, name: "Estoque Atual", state: {checked: false} },
                    { id: 17, name: "Valor Em Estoque", state: { checked: false } },
                    { id: 20, name: "Faturamento Por Centro de Custo", state: { checked: false } },
                    { id: 21, name: "Receitas x Despesas (Sintético)", state: { checked: false } },
                    { id: 22, name: "Entradas x Saídas", state: { checked: false } }
                ]
        }
    ]
}