<template>
    <div class="customer-admin">
        <PageTitle icon="fa fa-user" main="Cadastro de Clientes" sub />                  
        <b-form>            
            <input id="customer-id" type="hidden" v-model="customer.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="customer-name">
                            <b-form-input id="customer-name" type="text" 
                                v-model="customer.name" required                       
                                placeholder="Informe o Nome do Cliente..." />
                        </b-form-group>         
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CPF:" label-for="customer-cpf">
                                    <b-form-input id="customer-cpf" type="number" 
                                        v-model="customer.cpf" 
                                        placeholder="Informe o CPF do Cliente..." />
                                </b-form-group> 
                                <b-form-group label="Data Nascimento:" label-for="customer-birthday">
                                    <b-form-input id="customer-birthday" type="date"
                                        v-model="customer.birthday"
                                        placeholder="Informe a Data de Nascimento do Cliente..." />
                                </b-form-group>         
                                <b-form-group label="E-mail:" label-for="customer-email">
                                    <b-form-input id="customer-email" type="text"
                                        v-model="customer.email" 
                                        placeholder="Informe o E-mail do Cliente..." />
                                </b-form-group>                                     
                                <b-form-group label="Rede Social:" label-for="customer-socialMedia">
                                    <b-form-input id="customer-socialMedia" type="text"
                                        v-model="customer.socialMedia" 
                                        placeholder="Rede Social..." />
                                </b-form-group>                                  
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label="RG:" label-for="customer-rg">
                                    <b-form-input id="customer-rg" type="number"
                                        v-model="customer.rg" 
                                        placeholder="Confirme o RG do Cliente..." />
                                </b-form-group>
                                <b-form-group label="Sexo:" label-for="customer-gender">
                                    <b-form-select v-model="customer.gender" :options="genders"></b-form-select>
                                </b-form-group>        
                                <b-form-group label="Fone:" label-for="customer-phone">
                                    <b-form-input id="customer-phone" type="number"
                                        v-model="customer.phone" 
                                        placeholder="Informe o Fone do Cliente..." />
                                </b-form-group>                                 
                            </b-col>        
                            <b-col md="6" sm="6">
                                <b-form-group class="mt-3" label="Imagem:" label-for="customer-image">
                                    <b-form-file v-model="image" placeholder="Selecione um imagem" class="w-auto flex-grow-1"></b-form-file>
                                </b-form-group>
                            </b-col>   
                            <b-col md="6" sm="6" class="mt-5">
                                <b-button v-if="customer.image" variant="danger" class="ml-3" @click="clearImage">Limpar Imagem</b-button>
                            </b-col>   
                        </b-row>               
                        <b-row>
                            <b-col md="6" sm="6">
                                <div>
                                    <b-img id="imgCustomer" :src="imageSrc" class="mb-3" fluid block rounded width="200" height="200" thumbnail></b-img> 
                                </div>
                            </b-col>                                                                
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Endereço" header-bg-variant="dark" header-text-variant="white">
                        <b-row>    
                            <b-col md="5" sm="12"> 
                                <b-form-input type="text" id="customer-cep" placeholder="CEP..." v-model="address.cep"></b-form-input>
                            </b-col>
                            <b-col md="1" sm="12"> 
                                <b-button @click="searchCEP"><i class="fa fa-search-plus"></i></b-button>
                            </b-col>                            
                        </b-row>
                        <b-row>
                            <b-col md="10" sm="12"> 
                                <b-form-group label="Logradouro:" label-for="customer-street" class="mt-3">
                                    <b-form-input id="customer-street" placeholder="Logradouro..." v-model="address.street"></b-form-input>
                                </b-form-group>
                            </b-col>                         
                            <b-col md="2" sm="12"> 
                                <b-form-group label="Número:" label-for="customer-number" class="mt-3">
                                    <b-form-input id="customer-number" placeholder="Bairro..." v-model="address.number"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Bairro:" label-for="customer-neighborhood">
                                    <b-form-input id="customer-neighborhood" placeholder="Bairro..." v-model="address.neighborhood"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Município:" label-for="customer-city">
                                    <b-form-input id="customer-city" placeholder="Município..." v-model="address.city"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" sm="12"> 
                                <b-form-group label="Complemento:" label-for="customer-complement">
                                    <b-form-input id="customer-complement" placeholder="Complemento..." v-model="address.complement"></b-form-input>
                                </b-form-group>
                            </b-col>           
                            <b-col md="4" sm="12">                    
                                <b-form-group label="UF:" label-for="customer-state">
                                    <b-form-select v-model="address.state" :options="states"></b-form-select>
                                </b-form-group>               
                            </b-col>                                              
                        </b-row>       
                    </b-card>   
                </b-col>                     
            </b-row>
        </b-form>
            
        <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import States from '../../default/states'

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
    name: "CustomerPage",
    components: {PageTitle},
    data: function() {
      return { 
        image: null,
        imageSrc: null,          
        customer: {},
        address: {},
        genders: [
            {value: 1, text: "Masculino"},
            {value: 2, text: "Feminino"}
        ],
        states: States.states,    
      }    
    },
    computed: {
        hasImage() {
            return !!this.image;
        }
    },    
    watch: {
        image(newValue, oldValue) {
        if (newValue !== oldValue) {
            if (newValue) {
            base64Encode(newValue)
                .then(value => {
                this.imageSrc = value;                
                })
                .catch(() => {
                this.imageSrc = null;
                });
            } else {
            this.imageSrc = null;
            }            
        }
        }
    },    
    methods: {          
        clearImage() {
            this.image = null
            this.imageSrc = null
            this.customer.image = null
        },
        save() {            
            this.customer.name = this.customer.name.toUpperCase()

            this.customer.image = this.imageSrc

            const method = this.customer._id ? 'put' : 'post'
            const id = this.customer._id ? `/${this.customer._id}` : ''        

            this.customer.address = {
                cep: this.address.cep,
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city:  this.address.city,
                complement: this.address.complement,
                state: this.address.state
            }

            axios[method](`${baseApiUrl}/customer${id}`, this.customer)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                })  
                .catch(showError)
        },  
        searchCEP() {     
            this.$viaCep.buscarCep(this.address.cep).then((obj) => {
                this.address = {
                    cep: obj.cep,
                    street: obj.logradouro,
                    neighborhood: obj.bairro,
                    city: obj.localidade,                    
                }                
            });
        },        
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.$route.params.customerSelected) {
            this.customer = this.$route.params.customerSelected            

            this.address = {
                cep: this.customer.address.cep,
                street: this.customer.address.street,
                number: this.customer.address.number,
                neighborhood: this.customer.address.neighborhood,
                city:  this.customer.address.city,
                complement: this.customer.address.complement,
                state: this.customer.address.state
            }

            if (this.customer.image != null) {
                this.imageSrc = this.customer.image
            }
        }
    }
}     
</script>

<style>

</style>