<template>
  <div class="extract-pages">
    <PageTitle icon="fa fa-money" main="Lançamentos" sub />
    <div class="extract-pages-tabs">        
        <b-button size="md" variant="success" @click="newExtract()">
            NOVO
        </b-button>      
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="extracts" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="info" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Detalhes'">
                    <i class="fa fa-info"></i>
                </b-button>                     
                <b-button size="sm" variant="danger" @click="removeExtract(row.item)" v-b-popover.hover.top="'Excluir'" class="ml-2">
                    <i class="fa fa-trash"></i>
                </b-button>                     
            </template>            
        </b-table>        

        <div v-if="extracts.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                   

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
    </div>
  </div> 
</template>

<script>
import PageTitle from "../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: 'Extracts',
    components: { PageTitle },
    data: function() {
      return {
        ftext: '',
        extracts: [],
        extractSelected: {},
        currentPage: 1,
        perPage: 10,         
        fields: [                 
            { key: 'date2', label: 'Data', sortable: true },
            { key: 'type', label: 'Tipo', sortable: true },
            { key: 'costCenter.name', label: 'Centro de Custo', sortable: true },
            { key: 'account.name', label: 'Conta Financeira', sortable: true },
            { key: 'value', label: 'Valor (R$)', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],      
      }
    },
    computed: {
      rows() {
        return this.extracts.length
      }
    },      
    methods: {
        newExtract() {
          this.$router.push({name: 'extractPage', params: { },})          
        },
        onRowSelected(itemSelected) {
          let extractSelected = {    
              _id: itemSelected._id,
              date: itemSelected.date,
              type: itemSelected.type,
              account: itemSelected.account,
              costCenter: itemSelected.costCenter,
              historic: itemSelected.historic,
              module: itemSelected.module,
              value: itemSelected.value,
              formPayment: itemSelected.formPayment
          }              

          this.$router.push({name: 'extractPage', params: { extractSelected },})            
        },       
        loadExtracts() {
            const url = `${baseApiUrl}/extract`
            axios.get(url).then(res => {
                this.extracts = res.data            

                Moment.locale('pt-br')

                for (let e of this.extracts) {
                  e.date2 = Moment(e.date).format('L')
                  e.value = Number(e.value).toFixed(2)
                }
            }).catch(showError)                      
        },
        removeExtract(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover o Lançamento?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  const id = itemSelected._id
                  axios.delete(`${baseApiUrl}/extract/${id}`).then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadExtracts()
                  }).catch(showError)                
                }                
            }).catch()   
        }            
    },
    mounted() {
        this.loadExtracts()
    }      
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>