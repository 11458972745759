<template>
 <div class="incomeAndExpensesSynthetic">
    <PageTitle icon="fa fa-line-chart" main="Receitas x Despesas Sintético" sub="" />
        <div class="incomeAndExpensesSynthetic-page-tabs">
            <b-row>
                <b-col md="12" sm="12" class="mt-3">
                    <b-card class="card" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Inicial:" label-for="bgans-date-Ini" class="mt-3">
                                    <b-form-input id="bgans-date-Ini" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Final:" label-for="bgans-date-Fim" class="mt-3">
                                    <b-form-input id="bgans-date-Fim" type="date" v-model="dateFinish" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-button variant="warning" class="mt-5" @click="searchIncomeAndExpensesSynthetic">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generating" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
            <b-row>
            </b-row>
        </div>
  </div>   
</template>

<script>
import PageTitle from "../template/PageTitle"
import Moment from 'moment'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: "incomeAndExpensesSynthetic",
    components: { PageTitle },    
    data: function() {
        return {
            dateInitial: null,
            dateFinish: null,
            loading: false,
            totals: null,
            generating: false,  
            textBtnPrint: 'GERAR'      
        }
    },
    methods: {
        async print() {        
            Moment.locale('pt-br')                          

            let content = []

            content.push({
                type: 'table',
                topicFields: ['Totais'],
                fields: [
                    {
                        original: 'name',
                        exibition: 'Centro de Custo'
                    },                    
                    {
                        original: 'totalIncome',
                        exibition: 'Receitas (R$)'
                    },                                      
                    {
                        original: 'totalExpenses',
                        exibition: 'Despesas (R$)'
                    },
                    {
                        original: 'balance',
                        exibition: 'Balanço (R$)'
                    }                       
                ],

                list: this.totals
            })                  

            let contentPdf = {
                title: `RECEITAS X DESPESAS (SINTÉTICO) - ${Moment(this.dateInitial).format('L')} - ${Moment(this.dateFinish).format('L')}`,
                format: 'A4',
                orientation: 'portrait',
                separate: false,                                
                content: content
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

              this.generating = false
              this.textBtnPrint = 'GERAR'                         
            }).catch(showError)                  
        },        
        searchIncomeAndExpensesSynthetic() {
            let valid = true

            if (this.dateInitial == null || this.dateInitial == '') {
                this.$toasted.global.defaultError({msg: 'Informe a data Inicial.'})
                valid = false
            } 

            if (this.dateFinish == null  || this.dateFinish == '') {
                this.$toasted.global.defaultError({msg: 'Informe a data Final.'})
                valid = false
            }

            if (valid) {
                this.generating = true
                this.textBtnPrint = 'Aguarde...'          

                const url = `${baseApiUrl}/extract/incomeAndExpensesSynthetic/${this.dateInitial}&${this.dateFinish}`
                axios.get(url).then(res => {
                    this.totals = res.data

                    this.print()
                }).catch(showError)
            }
        },
        loadCostCenters() {
            const url = `${baseApiUrl}/costCenter`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.costCenters.push({ value: r.name,  text: r.name })                        
                }
            }).catch(showError)                        
        }        
    }
}
</script>

<style>

</style>