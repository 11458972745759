<template>
  <div class="stockActual-pages">
    <PageTitle icon="fa fa-line-chart" main="Estoque Atual" sub />
  
    <div class="stockActual-pages-tabs">
        <div v-if="listStockActual.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>  
        <div v-else >
            <ul>
                <li :v-for="item in listStockActual">{{item.name}}</li>
            </ul>
            <!-- <b-list-group>                 -->
                <!-- <b-list-group-item :v-for="item in listStockActual"> -->
                    
                    <!-- <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="listStockActual" 
                        :fields="fields" responsive="sm" id="my-table" striped fixed>
                    </b-table>  -->
                <!-- </b-list-group-item>                 -->
            <!-- </b-list-group>             -->
            <!-- <b-card header="{{l.name}}" bg-variant="default" header-bg-variant="dark" header-text-variant="white"> -->
                <!-- <b-table :v-for="l in listStockActual" ref="selectableTable" head-variant="light" outlined hover :items="l" 
                    :fields="fields" responsive="sm" id="my-table" striped fixed>
                </b-table>  -->
            <!-- </b-card>             -->
        </div>
        <b-button class="mt-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>         
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"

export default {
    name: 'StockActual',
    components: { PageTitle },
    data: function() {
      return {
          generatingPDF: false,
          textBtnPrint: 'IMPRIMIR',                 
          listStockActual: [],
          fields: [
              { key: 'name', label: 'Peça', sortable: true },
              { key: 'stockActual', label: 'Quantidade Estoque', sortable: true }
          ]
      }
    },
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'ESTOQUE ATUAL',
                format: 'A4',
                orientation: 'landscape',
                separate: false,                                
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Peça'
                          },
                          {
                            original: 'stockActual',
                            exibition: 'Quantidade Estoque'
                          }
                        ],
                        list: this.listStockActual
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'
            }).catch(showError)                  
        },        
        async loadStockCenters() {
            const url = `${baseApiUrl}/stockCenter`
            axios.get(url).then(res => {
                this.listStockActual = res.data      
            }).catch(showError)                              
        }
    },
    mounted() {
        this.loadStockCenters()  
    }    
}
</script>

<style>

</style>