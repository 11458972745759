<template>
  <div class="user-admin">
    <b-row>
      <b-col md="3" sm="12">
        <b-card header="E-mail" header-bg-variant="dark" header-text-variant="white">
          <b-form-group  label="Remetente">
            <b-input v-model="emailSender"></b-input>
          </b-form-group>
          <b-form-group  label="Senha">
            <b-input type="password" v-model="emailPassword"></b-input>
          </b-form-group>
          <b-form-group  label="SMTP">
            <b-input v-model="emailSMTP"></b-input>
          </b-form-group>
          <b-form-group label="Porta">
            <b-input type="number" v-model="emailPort"></b-input>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="2" sm="12">
        <b-card header="Agenda" header-bg-variant="dark" header-text-variant="white">
          <b-form-group label-align="center" label="Tempo Entre Atendimentos (min)">
            <b-input type="number" v-model="scaleTimeBetween"></b-input>
          </b-form-group>
          <b-form-group label="Data Inicial - Semana 1">
            <b-input type="date" v-model="scaleDateInitial"></b-input>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="2" sm="12">
        <b-card header="ANS" header-bg-variant="dark" header-text-variant="white">
          <b-form-group label="Identificação Prestador">
            <b-input type="number" v-model="providerIdentification"></b-input>
          </b-form-group>
          <b-form-group label="Registro ANS">
            <b-input type="number" v-model="registerANS"></b-input>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-button variant="success" @click="updateSettings" class="mt-3">SALVAR</b-button>  
  </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
  name: "GeneralAdmin",
  data: function() {
    return {  
      settings: null,
      emailSender: null,
      emailPassword: null,
      emailSMTP: null,
      emailPort: null,
      scaleTimeBetween: null,
      scaleDateInitial: null,
      providerIdentification: null,
      registerANS: null
    }
  },
  methods: {
    loadSettings() {
      const url = `${baseApiUrl}/setting`
      axios.get(url).then(res => {
          this.settings = res.data            

          if (res.data.length != 0) {
            let index = 0

            this.settings[0].params.find((item, i) => {
                if (item.name == 'emailRemetente') {
                    index = i
                }
            })   
            
            this.emailSender = this.settings[0].params[index].value

            this.settings[0].params.find((item, i) => {
                if (item.name == 'emailSenha') {
                    index = i
                }
            })   

            this.emailPassword = this.settings[0].params[index].value

            this.settings[0].params.find((item, i) => {
                if (item.name == 'emailSMTP') {
                    index = i
                }
            })   

            this.emailSMTP = this.settings[0].params[index].value

            this.settings[0].params.find((item, i) => {
                if (item.name == 'emailPorta') {
                    index = i
                }
            })   

            this.emailPort = this.settings[0].params[index].value

            this.settings[0].params.find((item, i) => {
                if (item.name == 'atendimentoTempoEntre') {
                    index = i
                }
            })   

            this.scaleTimeBetween = this.settings[0].params[index].value

            this.settings[0].params.find((item, i) => {
                if (item.name == 'dataInicialEscala') {
                    index = i
                }
            })  

            this.scaleDateInitial = this.settings[0].params[index].value

            this.settings[0].params.find((item, i) => {
                if (item.name == 'providerIdentification') {
                    index = i
                }
            })  

            this.providerIdentification = this.settings[0].params[index].value            

            this.settings[0].params.find((item, i) => {
                if (item.name == 'registerANS') {
                    index = i
                }
            })  

            this.registerANS = this.settings[0].params[index].value            
          }
      }).catch(showError)                      
    },
    updateSettings() {
        let params = []

        params.push(        
          {
            name: 'emailRemetente',
            type: 'String',
            value: this.emailSender,
            description: 'Conta do E-mail para envio de e-mails pelo sistema.'
          }
        )

        params.push(        
          {
            name: 'emailSenha',
            type: 'String',
            value: this.emailPassword,
            description: 'Senha do E-mail para envio de e-mails pelo sistema.'
          }
        )

        params.push(        
          {
            name: 'emailSMTP',
            type: 'String',
            value: this.emailSMTP,
            description: 'SMTP do serviço para envio de e-mails pelo sistema.'
          }
        )

        params.push(        
          {
            name: 'emailPorta',
            type: 'int',
            value: this.emailPort,
            description: 'Porta do serviço para envio de e-mails pelo sistema.'
          }
        )

        params.push(        
          {
            name: 'atendimentoTempoEntre',
            type: 'int',
            value: this.scaleTimeBetween,
            description: 'Tempo utilizado na distribuição de atendimentos dos profissionais.'
          }
        )

        params.push(        
          {
            name: 'dataInicialEscala',
            type: 'date',
            value: this.scaleDateInitial,
            description: 'Data inicial das escalas. Base para preenchimento de profissionais na agenda.'
          }
        )        

        params.push(        
          {
            name: 'providerIdentification',
            type: 'number',
            value: this.providerIdentification,
            description: 'Identificação do prestador na operadora.'
          }
        )        

        params.push(        
          {
            name: 'registerANS',
            type: 'number',
            value: this.registerANS,
            description: 'Registro na ANS.'
          }
        )        

      this.settings[0].params = params    

      const method = this.settings[0]._id ? 'put' : 'post'
      const id = this.settings[0]._id ? `/${this.settings[0]._id}` : ''            

      axios[method](`${baseApiUrl}/setting${id}`, this.settings[0])
        .then(() => {
            this.$toasted.global.defaultSuccess()
            this.loadSettings()
        })  
        .catch(showError)
    }
  },
  mounted() {
    this.loadSettings()
  }   
};
</script>

<style>
</style>