<template>
  <div class="customer-pages">
    <PageTitle icon="fa fa-user" main="Clientes" sub />
    <div class="customer-pages-tabs">        
        <b-button size="md" variant="success" @click="newCustomer()">
            NOVO
        </b-button>      
        <b-button class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>         
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="customers" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                    <i class="fa fa-pencil"></i>
                </b-button>

                <b-button class="ml-2" size="sm" variant="info" @click="historicLeases(row.item)" v-b-popover.hover.top="'Histórico de Locações'">
                    <i class="fa fa-list"></i>
                </b-button>                                
            </template>
        </b-table>        

        <b-modal size="lg" ok-only v-model="modalHistoricLeases" :title="customerSelected.name">
            <b-table head-variant="light" outlined hover :fields=fieldsHistoricLeases responsive="sm" id="my-table-historicLeases" 
              :items=customerHistoric :per-page="perPageHistoricLeases" :current-page="currentPageHistoricLeases" fixed>
            </b-table>

            <b-pagination pills v-model="currentPageHistoricLeases" :total-rows="rowsHistoricLeases" :per-page="perPageHistoricLeases" 
              aria-controls="my-table-historicLeases" class="page-item-historicLeases">
            </b-pagination>
        </b-modal>

        <div v-if="customers.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                   

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
    </div>
  </div> 
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: 'Customers',
    components: { PageTitle },
    data: function() {
      return {
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',        
        modalHistoricLeases: false,
        currentPageHistoricLeases: 1,
        perPageHistoricLeases: 10,         
        ftext: '',
        customers: [],
        customerSelected: {},
        customerHistoric: [],
        currentPage: 1,
        perPage: 10,         
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'cpf', label: 'CPF', sortable: true },
            { key: 'rg', label: 'RG', sortable: true },
            { key: 'phone', label: 'Fone', sortable: true },
            { key: 'email', label: 'E-mail', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],      
        fieldsHistoricLeases: [
          { key: 'professional', label: 'Profissional', sortable: true },
          { key: 'register', label: 'Registro', sortable: true },
          { key: 'accommodation', label: 'Local Atedimento', sortable: true },
          { key: 'date', label: 'Data', sortable: true },
          { key: 'start', label: 'Início', sortable: true },
          { key: 'finish', label: 'Término', sortable: true },
        ]
      }
    },
    computed: {
      rows() {
        return this.customers.length
      },
      rowsHistoricLeases() {
        return this.customerHistoric.length
      }
    },      
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'CLIENTES',
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Nome'
                          },
                          {
                            original: 'cpf',
                            exibition: 'CPF'
                          },                                                                   
                          {
                            original: 'email',
                            exibition: 'Email'
                          },
                          {
                            original: 'phone',
                            exibition: 'Fone'
                          },
                        ],
                        list: this.customers
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'
            }).catch(showError)                  
        },
        newCustomer() {
          this.$router.push({name: 'customerPage', params: { },})          
        },
        onRowSelected(itemSelected) {
          let customerSelected = {    
              _id: itemSelected._id,
              name: itemSelected.name,
              birthday: itemSelected.birthday,
              gender: itemSelected.gender,
              cpf: itemSelected.cpf,
              rg: itemSelected.rg,
              email: itemSelected.email,
              phone: itemSelected.phone,
              image: itemSelected.image,
              address: itemSelected.address,
              socialMedia: itemSelected.socialMedia
          }              

          this.$router.push({name: 'customerPage', params: { customerSelected },})            
        },       
        historicLeases(itemSelected) {
            this.customerSelected = itemSelected

            // const url = `${baseApiUrl}/scale/customer/${itemSelected.cpf}`

            Moment.locale('pt-br')

            this.customerHistoric = []

            // axios.get(url).then(res => {                         
            //   if (res.data.length > 0) {
            //       for (let r of res.data) {                    
            //         for (let pr of r.professionals) {                                            
            //           for (let a of pr.attendance) {
            //             for (let pa of a.patients) {
            //               if (pa.cpf == this.patientSelected.cpf) {
            //                 this.patientsHistoric.push({
            //                   date: Moment(r.date).format('L'),
            //                   professional: pr.name,
            //                   register: pr.adviceNumber,
            //                   accommodation: pa.accommodation.building + ' -> ' + 
            //                                  pa.accommodation.floor + ' -> ' + 
            //                                  pa.accommodation.apartment + ' -> ' + 
            //                                  pa.accommodation.name,
            //                   start: pa.startDone ? pa.startDone : pa.start,
            //                   finish: pa.finishDone ? pa.finishDone : pa.finish, 
            //                   _cellVariants: {
            //                     date: pa.startDone ? 'success' : '',
            //                     professional: pa.startDone ? 'success' : '',
            //                     register: pa.startDone ? 'success' : '',
            //                     accommodation: pa.startDone ? 'success' : '',
            //                     start: pa.startDone ? 'success' : '',
            //                     finish: pa.startDone ? 'success' : ''
            //                   }
            //                 })
            //               }
            //             }
            //           }
            //         }
            // //       }

            //       this.modalHistoricLeases = !this.modalHistoricLeases
            //     }                    
            // }).catch(showError)      
        },                     
        loadCustomers() {
            const url = `${baseApiUrl}/customer`
            axios.get(url).then(res => {
                this.customers = res.data            
            }).catch(showError)                      
        }    
    },
    mounted() {
        this.loadCustomers()
    }      
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>