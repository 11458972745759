<template>
  <div class="logScreen-pages">
    <PageTitle icon="fa fa-th-list" main="Auditoria" sub="Registro de Log" />
        <div>
            <b-row>            
                <b-col md="2" sm="12">
                    <b-form-group label="Data:" label-for="filter-date" class="mt-3">
                        <b-input type="date" v-model="dateSelected"></b-input>
                    </b-form-group>
                </b-col>            
                <b-col md="3" sm="12">
                    <b-form-group label="Usuário:" label-for="filter-user" class="mt-3">
                        <b-form-select :options="users" v-model="userSelected"></b-form-select>                
                    </b-form-group>
                </b-col>      
                <b-col md="2" sm="12">
                    <b-form-group label="Tabela:" label-for="filter-table" class="mt-3">
                        <b-form-select :options="tables" v-model="tableSelected"></b-form-select>                
                    </b-form-group>
                </b-col>                      
                <b-col md="2" sm="12">
                    <b-button size="md" variant="warning" @click="filterLog()" class="mt-5">
                        CONSULTAR
                    </b-button>                      
                </b-col>                                
            </b-row>
        </div>
        <hr>    
        <b-table ref="selectableTable" striped :items="listLog" :fields="fields" @row-selected="onRowSelected" responsive="sm"
                 :per-page="perPage" :current-page="currentPage">
        </b-table>        

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
  name: "LogScreen",
  components: { PageTitle },
  data: function() {
    return {
      users: [],
      userSelected: null,      
      dateSelected: null,
      tables: [],
      tableSelected: null,
      listLog: [],
      fields: [                 
          { key: 'name', label: 'Nome', sortable: true },                
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'table', label: 'Tabela', sortable: true },
          { key: 'action', label: 'Ação', sortable: true },
          { key: 'date', label: 'Data', sortable: true },
          { key: 'hour', label: 'Hora', sortable: true },
      ],
      currentPage: 1,
      perPage: 15,             
    }
  },
  computed: {
    rows() {
      return this.listLog.length
    }
  },   
  methods: {
    loadUsers() {
      const url = `${baseApiUrl}/users`
      axios.get(url).then(res => {
        this.users.push({ value: null,  text: '' })                        
        for (let r of res.data) {
          this.users.push({ value: r.cpf,  text: r.name })                        
        }
      }).catch(showError)
    },
    loadTables() {
      this.tables.push(
        {
          value: null, text: ''
        },
        {
          value: 'admission', text: 'ADMISSÃO'
        },
        {
          value: 'speciality', text: 'ÁREA DE ATUAÇÃO'
        },
        {
          value: 'advice', text: 'CONSELHOS'
        },
        {
          value: 'accessProfile', text: 'CONTROLE DE ACESSO'
        },
        {
          value: 'covenants', text: 'CONVÊNIOS'
        },
        {
          value: 'scale', text: 'ESCALAS'
        },
        {
          value: 'accommodation', text: 'LOCAIS DE INTERNAÇÃO'
        },        
        {
          value: 'auth', text: 'LOGIN/LOGOUT'
        },        
        {
          value: 'patient', text: 'PACIENTES'
        },
        {
          value: 'professional', text: 'PROFISSIONAIS'
        },
        {
          value: 'typeService', text: 'TIPOS DE ATENDIMENTO'
        },
        {
          value: 'user', text: 'USUÁRIOS'
        },                                
      )
    },
    filterLog() {
      if (this.dateSelected == null) {
        this.$toasted.global.defaultError({msg: 'Data não informada.'})
      } else {
        const url = `${baseApiUrl}/log/${this.dateSelected}&${this.userSelected}&${this.tableSelected}`
        axios.get(url).then(res => {
          this.listLog = []

          for (let r of res.data) {
            for (let u of r.users) {
              for (let a of u.actions) {
        
              let tab = ''

              switch (a.table) {
                case 'patient': tab = 'PACIENTES'                
                  break;
                case 'professional': tab = 'PROFISSIONAIS'
                  break;          
                case 'admission': tab = 'ADMISSÃO'                
                  break;          
                case 'speciality': tab = 'ÁREA DE ATUAÇÃO'
                  break;
                case 'advice': tab = 'CONSELHOS'
                  break;        
                case 'covenants': tab = 'CONVÊNIOS'
                  break;        
                case 'accessProfile': tab = 'CONTROLE DE ACESSO'
                  break;   
                case 'scale': tab = 'ESCALAS'
                  break;              
                case 'accommodation': tab = 'LOCAIS DE INTERNAÇÃO'
                  break;               
                case 'auth': tab = 'LOGIN/LOGOUT'
                  break;      
                case 'typeService': tab = 'TIPOS DE ATENDIMENTO'
                  break;            
                case 'user': tab = 'USUÁRIOS'
                  break;

                default:
                  tab = ''
              }            

              let act = ''

              switch (a.action) {
                case 'insert': act = 'INSERIR'                
                  break;
                case 'update': act = 'ALTERAR'
                  break;          
                case 'get': act = 'CONSULTAR'                
                  break;          
                case 'delete': act = 'REMOVER'                
                  break;          
                case 'processing': act = 'PROCESSAMENTO'                
                  break;        
                case 'scaleAutomatic': act = 'DISTRIBUIÇÃO AUTOMÁTICA'                
                  break;          
                case 'scaleProfessionalUpdate': act = 'ATUALIZAÇÃO DE PROFISSIONAL'                
                  break;                                
                case 'scaleProfessionalDelete': act = 'PROFISSIONAL REMOVIDO'                
                  break;             
                case 'scalePatientDelete': act = 'PACIENTE REMOVIDO'                
                  break;             
                case 'scalePatientInsert': act = 'PACIENTE INSERIDO'                
                  break;              
                case 'scaleProfessionalInsert': act = 'PROFISSIONAL INSERIDO'                
                  break;       
                case 'released': act = 'ALTA PACIENTE'                
                  break;       
                case 'scaleNotation': act = 'APONTAMENTO DE ATENDIMENTO'                
                  break;      
                case 'scaleEvolution': act = 'EVOLUÇÃO'                
                  break;    
                case 'scaleAnamnesis': act = 'LAUDO'                
                  break;                    
                                                    
                default:
                  act = a.action.toUpperCase()
              }          
              
              Moment.locale('pt-br')

              let dt = Moment(r.date).format('L')            
              
              if (this.tableSelected != null) {
                console.log(this.tableSelected)
                console.log(a.table)
                if (this.tableSelected == a.table) {
                  this.listLog.push({
                    date: dt,
                    name: u.name,
                    cpf: u.cpf,
                    table: tab,
                    action: act,
                    hour: a.hour
                  })
                }
              } else {
                this.listLog.push({
                  date: dt,
                  name: u.name,
                  cpf: u.cpf,
                  table: tab,
                  action: act,
                  hour: a.hour
                })
              }
            }
          }
        }          
      }).catch(showError)        
    }
  },
  onRowSelected() {

  }
},
mounted() {
  this.loadUsers() 
  this.loadTables() 
}
}
</script>

<style>

</style>