<template>
  <div class="purchase-admin">
    <PageTitle icon="fa fa-shopping-cart" main="Compra de Peças" sub />
    <b-form>
      <input id="purchase-id" type="hidden" v-model="purchase.id" />
      <b-row>
        <b-col md="12" sm="12">
          <b-card bg-variant="default" header="Dados Gerais" header-bg-variant="dark" header-text-variant="white">
            <b-row>         
              <b-col md="2" sm="12">
                <b-form-group label="Data:" label-for="purchase-date">
                  <b-form-input id="purchase-date" type="date" v-model="purchase.date"/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Data Vencimento:" label-for="purchase-dueDate">
                  <b-form-input id="purchase-dueDate" type="date" v-model="purchase.dueDate"/>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12">
                <b-form-group label="Fornecedor:" label-for="provider-name">
                  <b-form-input id="provider-name" type="text" v-model="provider.name" disabled/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="CNPJ:" label-for="provider-cnpj">
                  <b-form-input id="provider-cnpj" type="number" v-model="provider.cnpj" disabled/>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="6" class="align-self-center">
                <b-button class="mt-3" v-if="!purchase._id" v-b-modal.modal-prevent-closing @click="setModal('provider')"><i class="fa fa-search"></i></b-button>
                    <Lookup v-if="modal === 'provider'" ref="LookupProvider" title="Fornecedores" :list="providers" :fields="fieldsProvider"
                        :invalidFeedback="invalidFeedback" resultTable="provider" @event="addProvider">
                    </Lookup>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" sm="12">
                <b-form-group label="Parcelas:" label-for="purchase-parcel">
                  <b-form-input id="purchase-parcel" type="number" v-model="purchase.parcel"/>
                </b-form-group>
              </b-col>              
              <b-col md="2" sm="12">
                <b-form-group label="Centro de Estoque:" label-for="purchase-stockCenter">
                  <b-form-select id="purchase-stockCenter" v-model="purchase.stockCenter" :options="stockCenters"></b-form-select>
                </b-form-group>
              </b-col>                 
              <b-col md="2" sm="12">
                <b-form-group label="Centro de Custo:" label-for="purchase-costCenter">
                  <b-form-select id="purchase-costCenter" v-model="purchase.costCenter" :options="costCenters"></b-form-select>
                </b-form-group>
              </b-col>                     
              <b-col md="2" sm="12">
                <b-form-group label="Conta Financeira:" label-for="purchase-account">
                  <b-form-select id="purchase-account" v-model="purchase.account" :options="accounts"></b-form-select>
                </b-form-group>
              </b-col>                     
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
          <b-card bg-variant="default" header="Peças" header-bg-variant="dark" header-text-variant="white">
            <b-row>
              <b-col md="1" sm="6" class="align-self-center">
                <b-button v-if="!purchase._id" variant="success" v-b-modal.modal-prevent-closing @click="setModal('product')" v-b-popover.hover.top="'Adicionar'">
                    <i class="fa fa-plus"></i></b-button>
                    <Lookup v-if="modal === 'product'" ref="LookupProduct" title="Peças" :list="products" :fields="fieldsProductsLookup"
                        :invalidFeedback="invalidFeedback" resultTable="product" @event="addProduct">
                    </Lookup>
              </b-col>
              <hr>
            </b-row>
            <b-row>
                <b-table ref="selectableTable" head-variant="light" outlined hover :items="productsSelected" :fields="fieldsProducts" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext" class="mt-3 ml-3 mr-3">
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Excluir'">
                            <i class="fa fa-pencil"></i>
                        </b-button>                     
                    </template>
                </b-table>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
          <b-card bg-variant="default" header="Totais" header-bg-variant="dark" header-text-variant="white">
            <b-row>
              <b-col md="1" sm="12">
                <b-form-group label="Impostos:" label-for="purchase-taxes">
                  <b-form-input id="purchase-taxes" type="number" v-model="purchase.taxes" @input="totalization"/>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="12">
                <b-form-group label="Frete:" label-for="purchase-shipping">
                  <b-form-input id="purchase-shipping" type="number" v-model="purchase.shipping" @input="totalization"/>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="12">
                <b-form-group label="Desconto:" label-for="purchase-discount">
                  <b-form-input id="purchase-discount" type="number" v-model="purchase.discount" @input="totalization"/>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="12">
                <b-form-group label="Total Geral:" label-for="purchase-value">
                  <b-form-input id="purchase-value" type="number" v-model="purchase.value" disabled/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-button v-if="!purchase._id" variant="success" @click="save" class="mt-3">SALVAR</b-button>
    <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>
  </div>
</template>
<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"
import Lookup from "../default/Lookup"

export default {
  name: "AdmissionPage",
  components: { PageTitle, Lookup },
  data: function () {
    return {
        purchase: {},
        provider: {},
        providers: [],
        products: [],
        productsSelected: [],
        stockCenters: [],
        stockCenter: null, 
        costCenters: [],
        costCenter: null,
        accounts: [],
        account: null,
        resultLookupProvider: {},
        modal: null,
        currentPage: 1,
        perPage: 10,   
        ftext: '',        
        invalidFeedback: "",
        fieldsProducts: [
            { key: "name", label: "Nome", sortable: true },
            { key: "unit", label: "Unidade Medida", sortable: true },
            { key: "count", label: "Quantidade", sortable: true },
            { key: "valueUnit", label: "Valor Unit (R$)", sortable: true },
            { key: "value", label: "Valor Total (R$)", sortable: true },
        ],
        fieldsProductsLookup: [            
            { key: "name", label: "Nome", sortable: true },
            { key: "unit", label: "Unidade Medida", sortable: true },
            { key: "count", label: "Quantidade", sortable: true },
            { key: "valueUnit", label: "Valor Unit (R$)", sortable: true },
        ],
        fieldsProvider: [
            { key: "name", label: "Nome", sortable: true },
            { key: "cnpj", label: "CNPJ", sortable: true },
        ]
    }
  },
  methods: {
    save() {
      this.purchase.provider = this.provider
      this.purchase.products = this.productsSelected

      this.purchase.taxes = Number(this.purchase.taxes).toFixed(2)
      this.purchase.shipping = Number(this.purchase.shipping).toFixed(2)
      this.purchase.discount = Number(this.purchase.discount).toFixed(2)
      this.purchase.value = Number(this.purchase.value).toFixed(2)

      this.purchase.idMaster = Math.random()

      const method = this.purchase._id ? 'put' : 'post'
      const id = this.purchase._id ? `/${this.purchase._id}` : ''        

      axios[method](`${baseApiUrl}/purchase${id}`, this.purchase)
          .then(() => {
              this.$toasted.global.defaultSuccess()
              this.$router.go(-1)
          })  
          .catch(showError)
    },
    cancel() {
        this.$router.go(-1)
    },
    totalization() {
        let total = 0

        for (let p of this.productsSelected) {
            total = Number(total) + Number(p.value)
        }

        this.purchase.value = Number(total + Number(this.purchase.shipping) + Number(this.purchase.taxes) - Number(this.purchase.discount)).toFixed(2)
    },
    addProvider(payload) {
      this.provider = {
        _id: payload.providerSelected._id,
        name: payload.providerSelected.name,
        cnpj: payload.providerSelected.cnpj
      }
    },
    addProduct(payload) {
        this.productsSelected.push({
            _id: payload.productSelected._id,
            name: payload.productSelected.name,
            unit: payload.productSelected.unit,
            count: Number(payload.productSelected.count).toFixed(2),
            valueUnit: Number(payload.productSelected.valueUnit).toFixed(2),
            value: Number(payload.productSelected.count * payload.productSelected.valueUnit).toFixed(2)
        })

        this.totalization()
    },
    loadProviders() {
        const url = `${baseApiUrl}/provider`
        axios.get(url).then(res => {
            this.providers = res.data            
        }).catch(showError)                              
    },
    loadProducts() {
        const url = `${baseApiUrl}/product`
        axios.get(url).then(res => {
            this.products = res.data            
        }).catch(showError)                              
    },  
    loadAccounts() {
        const url = `${baseApiUrl}/account`
        axios.get(url).then(res => {
            for (let d of res.data) {
                this.accounts.push({
                    value: { _id: d._id, name: d.name }, text: d.name
                })
            }
        }).catch(showError)                              
    }, 
    loadStockCenters() {
        const url = `${baseApiUrl}/stockCenter`
        axios.get(url).then(res => {
            for (let d of res.data) {
                this.stockCenters.push({
                    value: { _id: d._id, name: d.name, listProducts: d.listProducts }, text: d.name
                })
            }
        }).catch(showError)                              
    },  
    loadCostCenters() {
        const url = `${baseApiUrl}/costCenter`
        axios.get(url).then(res => {
            for (let d of res.data) {
                this.costCenters.push({
                    value: { _id: d._id, name: d.name }, text: d.name
                })
            }
        }).catch(showError)       
    },         
    setModal(param) {
        this.modal = param
    }            
  },
  mounted() {
    this.loadProviders()
    this.loadProducts()
    this.loadAccounts()
    this.loadCostCenters()
    this.loadStockCenters()

    if (this.$route.params.purchaseSelected) {
      this.purchase = this.$route.params.purchaseSelected      
      this.account = this.purchase.account
      this.costCenter = this.purchase.costCenter
      this.stockCenter = this.purchase.stockCenter
      this.provider = this.purchase.provider
      this.productsSelected = this.purchase.products    

      document.getElementById('purchase-stockCenter').disabled = true
      document.getElementById('purchase-costCenter').disabled = true            
      document.getElementById('purchase-account').disabled = true            
      document.getElementById('purchase-date').disabled = true
      document.getElementById('purchase-taxes').disabled = true
      document.getElementById('purchase-shipping').disabled = true
      document.getElementById('purchase-discount').disabled = true
      document.getElementById('purchase-parcel').disabled = true
      document.getElementById('purchase-dueDate').disabled = true
    } else {
      this.purchase = {
        parcel: 1,
        taxes: Number(0.00).toFixed(2),
        discount: Number(0.00).toFixed(2),
        shipping: Number(0.00).toFixed(2),
        value: Number(0.00).toFixed(2)
      }
    }
  }
}
</script>

<style>
</style>