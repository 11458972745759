<template>
    <div class="product-admin">
        <PageTitle icon="fa fa-cog" main="Cadastro de Peças" sub />                  
        <b-form>            
            <input id="product-id" type="hidden" v-model="product.id"/>
            <b-row>
                <b-col md="4" sm="4">
                    <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-form-group label="Nome:" label-for="product-name">
                                    <b-form-input id="product-name" type="text" 
                                        v-model="product.name" required                       
                                        placeholder="Informe o Nome da Peça..." />
                                </b-form-group>         
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="7" sm="12">
                                <b-form-group label="Unidade Medida:" label-for="product-unit">
                                    <b-form-select v-model="product.unit" :options="units"></b-form-select>
                                </b-form-group>         
                            </b-col>
                            <b-col md="5" sm="12">
                                <b-form-group label="Estoque Mínimo:" label-for="product-stockMinimum">
                                    <b-form-input id="product-stockMinimum" type="number" 
                                        v-model="product.stockMinimum"/>
                                </b-form-group>         
                            </b-col>
                        </b-row>                                                
                    </b-card>   
                </b-col>                     
            </b-row>
        </b-form>
            
        <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: "ProductPage",
    components: {PageTitle},
    data: function() {
      return { 
        product: {},
        units: [
            {value: 'UNIDADE', text: "UNIDADE"},
            {value: 'QUILO', text: 'QUILO'},
            {value: 'GRAMA', text: 'GRAMA'},
            {value: 'CAIXA', text: 'CAIXA'},
            {value: 'METRO', text: 'METRO'},
            {value: 'CENTÍMETRO', text: 'CENTÍMETRO'},
            {value: 'MILÍMETRO', text: 'MILÍMETRO'},
        ],        
      }    
    },
    methods: {          
        save() {
            this.product.name = this.product.name.toUpperCase()

            const method = this.product._id ? 'put' : 'post'
            const id = this.product._id ? `/${this.product._id}` : ''        

            axios[method](`${baseApiUrl}/product${id}`, this.product)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                })  
                .catch(showError)
        },  
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.$route.params.productSelected) {
            this.product = this.$route.params.productSelected            
        }
    }
}     
</script>

<style>

</style>