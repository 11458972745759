<template>
  <aside class="menu" v-show="isMenuVisible">
    <div class="menu-filter">
      <i class="fa fa-search fa-lg"></i>
      <input type="text" placeholder="Digite para filtrar..." v-model="treeFilter" class="filter-field"/>
    </div>
    <Tree :data="treeData" :options="treeOptions" :filter="treeFilter" ref="tree" />
  </aside>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import { mapState } from "vuex";
import Tree from "liquor-tree";
import TreeMain from "../default/Tree"

export default {
  name: "Menu",
  components: { Tree },
  computed: mapState(["isMenuVisible"]),
  data: function () {
    return {
      treeFilter: "",
      treeData: TreeMain.treeMain,
      treeOptions: {        
        propertyNames: { text: "name" },
        filter: { emptyText: "Categoria não encontrada" },        
      },
      accessProfile: null,
    };
  },
  methods: {
    loadMenu() {
      const id = this.$store.state.user['accessProfile']   
      
      const url = `${baseApiUrl}/accessProfile/${id}`      

      axios.get(url).then(res => {        

        this.accessProfile = res.data        

        let selections = []

        selections = this.$refs.tree.findAll({})        

        let finded = false        

        if (res.data != null) {
          selections.forEach(s => {                
              this.accessProfile.access.forEach(a => {                                 
                  if (s.text === a.name) {                        
                      finded = true // Encontrou acesso e fez o tratamento de exibição..

                      if (!a.state && s.children.length == 0) {
                        s.states['visible'] = false
                      }          
                  }                                                    
              })

              if (!finded) {
                s.states['visible'] = false
              }

              finded = false
          })        
        }
      }).catch(showError)  
    },
    onNodeSelect(node) {
      let way = '';
      switch (node.id) {
        case 2: way = 'customer'                
          break;
        case 3: way = 'equipment'                
          break;          
        case 4: way = 'employee'                
          break;     
       case 5: way = 'product'                
          break;                     
       case 6: way = 'provider'                
          break; 
       case 9: way = 'purchases'                
          break;           
       case 10: way = 'account'                
          break; 
       case 12: way = 'extract'                
          break;           
       case 13: way = 'costCenter'                
          break;           
       case 14: way = 'stockCenter'                
          break;    
       case 15: way = 'removal'                
          break;                   
       case 17: way = 'valueStock'                
          break;         
       case 18: way = 'stockActual'                
          break;        
       case 19: way = 'typeEquipment'                
          break;      
       case 20: way = 'billingPerCostCenter'
          break;      
       case 21: way = 'incomeAndExpensesSynthetic'
          break;       
       case 22: way = 'entranceRemoval'                                     
          break;
        default:
          way = '';
      }          
      
      if (way !== '') { 
        this.$router.push({name: way, params: { },})
        if (this.$mq === "xs" || this.$mq === "sm") {          
          this.$store.commit("toggleMenu", false);
        }
      }
    },
  },
  mounted() {  
    this.$refs.tree.$on("node:selected", this.onNodeSelect);        
    this.loadMenu()
  },
};
</script>

<style>
.menu {
  grid-area: menu;
  background: #c9dfeb;
  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.menu span,
.menu span:hover {
  color: black;
  text-decoration: none;
}
.menu .tree {  
  font-size: 16px;
  font-weight: bold;
}
.menu .tree-node.selected > .tree-content,
.menu .tree-node .tree-content:hover {
  background-color:#cccccc;
}
.menu .tree-node:not(.selected) > .tree-content:hover {
  background:#cccccc;
}
.tree-arrow.has-child {
  filter: black;
}
.menu .menu-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #aaa;
}
.menu .menu-filter i {
  color: #aaa;
  margin-right: 10px;
}
.menu input {
  color: #ccc;
  font-size: 1.3rem;
  border: 0;
  outline: 0;
  width: 100%;
  background: transparent;
}
.tree-filter-empty {
  color: #ccc;
  font-size: 1.3rem;
  margin-left: 20px;
}
</style>