<template>
    <div class="lookup">
        <b-modal id="modal-prevent-closing" ref="modal" :title=title @show="resetModal" @hidden="resetModal" @ok="handleOk" size="lg">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
                <hr>
                <b-form-group :invalid-feedback=invalidFeedback :state="stateResult">
                    
                    <b-table head-variant="light" outlined hover :fields=fields responsive="sm" id="my-table" :items=list
                            :per-page="perPage" :current-page="currentPage" fixed :filter="ftext" selectable :select-mode="selectMode"
                            @row-selected="onRowSelected">

                            <template v-if="resultTable == 'product' || resultTable == 'productRemoval'" v-slot:cell(count)>
                                <b-input type="number" required @input="setCount"></b-input>
                            </template>

                            <template v-if="resultTable == 'product'" v-slot:cell(valueUnit)>
                                <b-input type="number" required @input="setValueUnit"></b-input>
                            </template>                            

                            <template v-if="resultTable == 'productRemoval'" v-slot:cell(stockCenter)>
                                <b-form-select id="stockCenter" v-model="stockCenter" :options=stockCenters></b-form-select>
                            </template>         
                    </b-table>        

                    <div v-if="rows == 0" class="d-flex justify-content-center mb-3">    
                        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
                    </div>

                    <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
                    </b-pagination>
                </b-form-group>                                       
            </form>
        </b-modal>                                          
    </div>
</template>

<script>

export default {
    name: 'Lookup',
    props: [
        'title', 
        'invalidFeedback',
        'list',
        'fields',
        'resultTable',    
        'type',
        'stockCenters'
    ],
    data: function() {
        return {
            ftext: '',
            currentPage: 1,
            perPage: 10,            
            selectMode: 'single',
            result: null,
            stateResult: null,
            description: '',
            count: null,
            valueUnit: null,
            stockCenter: null,
        }
    },
    computed: {
      rows() {
          if (this.list == null) {
            return 0
          } else {
            return this.list.length
          }
      }
    },    
    methods: {
        onRowSelected(itemSelected) {          
            if (itemSelected.length > 0) {
                if (this.resultTable === 'product') {
                    this.result = {
                        _id: itemSelected[0]._id,
                        name: itemSelected[0].name,
                        unit: itemSelected[0].unit,
                        count: null,
                        valueUnit: null
                    }    
                } else if (this.resultTable === 'productRemoval') {
                    this.result = {
                        _id: itemSelected[0]._id,
                        name: itemSelected[0].name,
                        unit: itemSelected[0].unit,
                        count: null,
                        stockCenter: null
                    }   
                } else {
                    this.result = itemSelected[0]           
                }
            }
        },
        resetModal() {
            this.result = null
            this.stateResult = null
            this.count = null
            this.valueUnit = null
            this.stockCenter = null
        }, 
        setCount(value) {
            this.count = value
        },         
        setValueUnit(value) {
            this.valueUnit = value
        },      
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        }, 
        checkFormValidity() {
            if (this.resultTable == 'productRemoval') {
                if (!this.stockCenter || this.result == null) {
                    return false
                } else {
                    return true
                }               
            } else {
                if (this.result == null) {
                    this.stateResult = false
                    return false
                } else {
                    this.stateResult = true
                    return true
                }
            }
        },     
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return 
            }

            if (this.resultTable === 'provider') {
                this.$emit('event', { providerSelected: this.result})
            } else if (this.resultTable === 'product') {
                this.result.count = this.count
                this.result.valueUnit = this.valueUnit
                this.$emit('event', { productSelected: this.result})     
            } else if (this.resultTable == 'productRemoval') {
                this.result.count = this.count
                this.result.stockCenter = {
                    _id: this.stockCenter._id,
                    name: this.stockCenter.name           
                }
                this.$emit('event', { productSelected: this.result})     
            } else if (this.resultTable === 'equipment') {
                this.$emit('event', { equipmentSelected: this.result})
            } else if (this.resultTable === 'mechanical') {
                this.$emit('event', { mechanicalSelected: this.result})
            } else {
                this.$store.commit("setResultLookup", this.result)
            }
            
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },
    }
}
</script>

<style>

</style>