<template>
  <div class="admin-pages">
    <PageTitle icon="fa fa-cogs" main="Administração do Sistema" sub="Usuários e Configurações" />
    <div class="admin-pages-tabs">
      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Usuários" active>
            <UserAdmin />
          </b-tab>
          <b-tab title="Perfis de Acesso">
            <AccessProfiles />
          </b-tab>
          <b-tab title="Geral">
            <GeneralPage />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import UserAdmin from "./UserAdmin"
import GeneralPage from "./GeneralPage"
import AccessProfiles from './AccessProfiles'

export default {
  name: "AdminPage",
  components: { PageTitle, UserAdmin, GeneralPage, AccessProfiles },
};
</script>

<style>
</style>