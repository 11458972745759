<template>
 <div class="billingPerCostCenter">
    <PageTitle icon="fa fa-line-chart" main="Faturamento por Centro de Custo" sub="" />
        <div class="billingPerCostCenter-page-tabs">
            <b-row>
                <b-col md="12" sm="12" class="mt-3">
                    <b-card class="card" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data:" label-for="bgans-date" class="mt-3">
                                    <b-form-input id="bgans-date" type="date" v-model="date" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Centro de Custo:" label-for="bgans-cost-center" class="mt-3">
                                    <b-form-select v-model="selectedCostCenter" :options="costCenters"></b-form-select>
                                </b-form-group>    
                            </b-col>
                            <b-col md="1" sm="12">
                                <b-form-group label="Tipo" class="mt-2">
                                    <b-form-radio v-model="selectedType" name="some-radios" value="S">Sintético</b-form-radio>
                                    <b-form-radio v-model="selectedType" name="some-radios" value="A">Analítico</b-form-radio>
                                </b-form-group>                                
                            </b-col>                            
                            <b-col md="2" sm="12">
                                <b-button variant="warning" class="mt-5" @click="searchBillingPerCostCenter">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generating" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
            <b-row>
            </b-row>
        </div>
  </div>   
</template>

<script>
import PageTitle from "../template/PageTitle"
import Moment from 'moment'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: "billingPerCostCenter",
    components: { PageTitle },    
    data: function() {
        return {
            date: null,
            loading: false,
            selectedCostCenter: null,
            costCenters: [],      
            listBillings: [],
            listGeneral: [],
            listEquipments: [],
            listDesp: [],
            nameEmployee: '',
            total: 0,
            totalGeneral: 0,
            totalDiscount: 0,
            totalDiscountGeneral: 0,
            totalLocations: 0,
            selectedType: 'S',
            generating: false,  
            textBtnPrint: 'GERAR'      
        }
    },
    methods: {
        async printS() {        
            Moment.locale('pt-br')                          

            let content = []        

            let listTopics = []    

            for (let lg of this.listGeneral) {
                listTopics = []

                await this.getEmployee(lg.employee)
               
                listTopics.push(`${this.nameEmployee} - Valor Abertura R$ ${lg.valueInit.toFixed(2)}`)

                content.push({
                    type: 'table',
                    topicFields: listTopics,
                    fields: [
                        {
                        original: 'typePayment',
                        exibition: 'Forma de Pagamento'
                        },
                        {
                        original: 'valueSystem',
                        exibition: 'Valor Sistema (R$)'
                        },                        
                        {
                        original: 'value',
                        exibition: 'Valor Informado (R$)'
                        },
                        {
                        original: 'diff',
                        exibition: 'Diferença (R$)'
                        }                        
                    ],

                    list: lg.billings
                })

                for (let b of lg.billings) {
                    if (b.typePayment != 'Descontos' && b.typePayment != 'Despesas') {
                        this.totalGeneral += parseFloat(b.valueSystem)
                    }
                }
            }

            content.push({
                type: 'table',
                topicFields: ['Despesas'],
                fields: [
                    {
                        original: 'description',
                        exibition: 'Descrição'
                    },                                      
                    {
                        original: 'value',
                        exibition: 'Valor (R$)'
                    }
                ],

                list: this.listDesp
            })            

            let listTotals = [
                {
                    'totalLocations': this.totalLocations,
                    'total': this.totalGeneral.toFixed(2),
                    'discount': this.totalDiscountGeneral.toFixed(2)
                }
            ]

            content.push({
                type: 'table',
                topicFields: ['Totais'],
                fields: [
                    {
                    original: 'totalLocations',
                    exibition: 'Nº de Locações'
                    },                                      
                    {
                    original: 'total',
                    exibition: 'Valor Total (R$)'
                    },
                    {
                    original: 'discount',
                    exibition: 'Total Desconto (R$)'
                    }                  
                ],

                list: listTotals
            })

            let contentPdf = {
                title: `FATURAMENTO POR CENTRO DE CUSTO - ${this.selectedCostCenter} - ${Moment(this.date).format('L')}`,
                format: 'A4',
                orientation: 'portrait',
                separate: false,                                
                content: content
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

              this.generating = false
              this.textBtnPrint = 'GERAR'                         
            }).catch(showError)                  
        }, 
        async printA() {        
            Moment.locale('pt-br')       
            
            let content = []        

            let listTopics = []      
            
            let listContent = []

            let vm = 0
            let vc = 0
            let vp = 0            

            for (let b of this.listBillings) {
                vm = 0
                vc = 0
                vp = 0

                listTopics = []
                listContent = []

                for (let p of b.payment) {
                    if (p.type == '0') {
                        vm += p.value
                    }

                    if (p.type == '1') {
                        vc += p.value
                    }        
                    
                    if (p.type == '3') {
                        vp += p.value
                    }
                }

                listTopics.push(`Valor Pago (R$): Dinheiro: ${vm.toFixed(2)} - Cartão: ${vc.toFixed(2)} - Pix: ${vp.toFixed(2)}`)

                for (let e of b.listEquipments) {
                    listContent.push({
                        customer: b.customer.name,
                        equipment: e.name,
                        number: e.number,
                        start: e.start,
                        finish: e.finish                        
                    })
                }

                content.push({
                type: 'table',
                topicFields: listTopics,
                fields: [
                    {
                    original: 'customer',
                    exibition: 'Cliente'
                    },
                    {
                    original: 'equipment',
                    exibition: 'Equipamento'
                    },     
                    {
                    original: 'number',
                    exibition: 'Número'
                    },                                          
                    {
                    original: 'start',
                    exibition: 'Início'
                    },
                    {
                    original: 'finish',
                    exibition: 'Final'
                    }                       
                ],

                    list: listContent
                })                  
            }                 

            let contentPdf = {
                title: `FATURAMENTO POR CENTRO DE CUSTO - ${this.selectedCostCenter} - ${Moment(this.date).format('L')}`,
                format: 'A4',
                orientation: 'portrait',
                separate: false,                                
                content: content
            }

            const url = `${baseApiUrl}/reports/pdf`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

              this.generating = false
              this.textBtnPrint = 'GERAR'                         
            }).catch(showError)                  
        },               
        async getEmployee(id) {           
            const urlEmp = `${baseApiUrl}/employee/${id}`
            
            await axios.get(urlEmp).then(res => {
                this.nameEmployee = res.data.name
            })
        },
        searchBillingPerCostCenter() {
            let valid = true

            this.listBillings = []
            this.listGeneral = []

            if (this.date == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data.'})
                valid = false
            } 

            if (!this.selectedCostCenter) {
                this.$toasted.global.defaultError({msg: 'Informe o Centro de Custo.'})
                valid = false
            }

            if (valid) {
                this.generating = true
                this.textBtnPrint = 'Aguarde...'   

                let emp = null

                this.total = 0
                this.totalGeneral = 0
                this.totalDiscount = 0
                this.totalDiscountGeneral = 0
                this.totalLocations = 0

                const url = `${baseApiUrl}/event/billingPerCostCenter/${this.date}&${this.selectedCostCenter}`
                axios.get(url).then(res => {
                    this.locations = res.data.locations

                    if (res.data.locations.length > 0) {
                        if (this.selectedType == 'S') {
                            let vm = 0
                            let vc = 0
                            let vp = 0
                            let vd = 0      
                            
                            let vms = 0
                            let vcs = 0
                            let vps = 0
                            let vds = 0 
                            
                            let vmdiff = 0
                            let vcdiff = 0
                            let vpdiff = 0
                            let vddiff = 0
                            
                            let vdesp = 0
                            
                            this.listEquipments = []

                            this.listBillings = []

                            res.data.locations.sort(function (a,b) {
                                if (a.employee > b.employee) return 1
                                if (a.employee < b.employee) return -1
                                return 0
                            })
                           
                            emp = res.data.locations[0].employee
                            
                            this.listDesp = []

                            for (let d of res.data.locations) {    
                                if (emp != d.employee) {                            
                                    this.listBillings.push({
                                        'typePayment': 'Dinheiro',
                                        'value': parseFloat(vm).toFixed(2),
                                        'valueSystem': parseFloat(vms).toFixed(2),
                                        'diff': parseFloat(vmdiff).toFixed(2)
                                    })

                                    this.listBillings.push({
                                        'typePayment': 'Cartão',
                                        'value': parseFloat(vc).toFixed(2),
                                        'valueSystem': parseFloat(vcs).toFixed(2),
                                        'diff': parseFloat(vcdiff).toFixed(2)                                    
                                    })
                                    
                                    this.listBillings.push({
                                        'typePayment': 'Pix',
                                        'value': parseFloat(vp).toFixed(2),
                                        'valueSystem': parseFloat(vps).toFixed(2),
                                        'diff': parseFloat(vpdiff).toFixed(2)                                    
                                    })   

                                    this.listBillings.push({
                                        'typePayment': 'Descontos',
                                        'value': parseFloat(vd).toFixed(2),
                                        'valueSystem': parseFloat(vds).toFixed(2),
                                        'diff': parseFloat(vddiff).toFixed(2)                                    
                                    })

                                    for (let e of res.data.employees) {
                                        if (e._id == emp) {  
                                            this.listGeneral.push({
                                                employee: emp,
                                                valueInit: parseFloat(e.valueInit),
                                                billings: this.listBillings
                                            })                                     
                                        }   
                                    }

                                    this.total = 0                                    
                                    this.totalDiscount = 0     

                                    emp = d.employee      
                                    
                                    this.total = this.total + vms + vcs + vps
                                    // this.totalGeneral = this.totalGeneral + vms + vcs + vps

                                    this.totalDiscount = this.totalDiscount + vd
                                    this.totalDiscountGeneral = this.totalDiscountGeneral + vd

                                    vm = 0
                                    vc = 0
                                    vp = 0      
                                    vd = 0       

                                    vms = 0
                                    vcs = 0
                                    vps = 0
                                    vds = 0 
                                    
                                    vmdiff = 0
                                    vcdiff = 0
                                    vpdiff = 0
                                    vddiff = 0    

                                    vdesp = 0
                                    
                                    this.listBillings = []
                                }
                                
                                let totLocation = 0
                                                                                                                                            
                                for (let p of d.payment) {
                                    if (p.type == '0') {
                                        vms += p.value

                                        this.total += p.value
                                        // this.totalGeneral += p.value

                                        totLocation += p.value
                                    } else if (p.type == '1') {
                                        vcs += p.value

                                        this.total += p.value
                                        // this.totalGeneral += p.value

                                        totLocation += p.value
                                    } else if (p.type == '2') {
                                        vds += p.value

                                        this.totalDiscount += p.value
                                        this.totalDiscountGeneral += p.value
                                    } else if (p.type == '3') {
                                        vps += p.value

                                        this.total += p.value
                                        // this.totalGeneral += p.value

                                        totLocation += p.value
                                    } else if (p.type == '4') {
                                        vdesp += p.value

                                        this.listDesp.push({
                                            employee: p.employee,
                                            value: p.value.toFixed(2),
                                            description: p.description
                                        })
                                    }                                
                                }   

                                let finded = false

                                let avg = parseFloat(((totLocation) / d.listEquipments.length).toFixed(2))
                                
                                for (let eql of d.listEquipments) {
                                    finded = false

                                    for (let eq of this.listEquipments) {
                                        if (eql.name == eq.name) {
                                            finded = true

                                            eq.count += 1

                                            eq.value += avg

                                            this.totalLocations += 1

                                            break
                                        }
                                    }

                                    if (!finded) {
                                        this.totalLocations += 1                                    

                                        this.listEquipments.push({
                                            name: eql.name,
                                            count: 1,
                                            value: avg
                                        })
                                    }                    
                                }
                            }   
                            
                            for (let eq of this.listEquipments) {
                                eq.value = eq.value.toFixed(2)
                            }
                            
                            let valueInit = 0

                            for (let e of res.data.employees) {
                                if (e._id == emp) {
                                    this.total += parseFloat(e.valueInit)

                                    valueInit = parseFloat(e.valueInit) 

                                    vms += parseFloat(e.valueInit)

                                    if (res.data.event.payments) {
                                        for (let pay of res.data.event.payments) {
                                            for (let p of pay) {
                                                if (p.employee == emp) {
                                                    if (p.type == '0') {
                                                        vm += p.value
                                                        vmdiff = p.diff
                                                    } else if (p.type == '1') {
                                                        vc += p.value
                                                        vcdiff = p.diff
                                                    } else if (p.type == '2') {
                                                        vd += p.value
                                                        vddiff = p.diff
                                                    } else if (p.type == '3') {
                                                        vp += p.value
                                                        vpdiff = p.diff
                                                    } else if (p.type == '4') {
                                                        vdesp += p.value

                                                        this.listDesp.push({
                                                            employee: p.employee,
                                                            value: p.value.toFixed(2),
                                                            description: p.description
                                                        })
                                                    }   
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            let diffFinal = vmdiff < 0 ? vmdiff + vdesp : vmdiff - vdesp
                        
                            this.listBillings.push({
                                'typePayment': 'Dinheiro',
                                'value': parseFloat(vm).toFixed(2),
                                'valueSystem': parseFloat(vms - vdesp).toFixed(2),
                                'diff': parseFloat(diffFinal).toFixed(2)                            
                            })

                            this.listBillings.push({
                                'typePayment': 'Cartão',
                                'value': parseFloat(vc).toFixed(2),
                                'valueSystem': parseFloat(vcs).toFixed(2),
                                'diff': parseFloat(vcdiff).toFixed(2)                            
                            })
                            
                            this.listBillings.push({
                                'typePayment': 'Pix',
                                'value': parseFloat(vp).toFixed(2),
                                'valueSystem': parseFloat(vps).toFixed(2),
                                'diff': parseFloat(vpdiff).toFixed(2)                            
                            })        
                            
                            this.listBillings.push({
                                'typePayment': 'Descontos',
                                'value': parseFloat(vd).toFixed(2),
                                'valueSystem': parseFloat(vds).toFixed(2),
                                'diff': parseFloat(vddiff).toFixed(2)                            
                            })
                            
                            this.listBillings.push({
                                'typePayment': 'Despesas',
                                'value': parseFloat(vdesp).toFixed(2),
                                'valueSystem': parseFloat(vdesp).toFixed(2),
                                'diff': parseFloat('0').toFixed(2)                            
                            })                            

                            this.listGeneral.push({
                                employee: emp,
                                valueInit: valueInit,
                                billings: this.listBillings
                            })                                                  
                            
                            this.printS()
                        } else {
                            this.listBillings = res.data.locations 

                            this.printA()
                        }
                    } else {
                        this.$toasted.global.defaultError({msg: 'Dados não encontrados.'}) 

                        this.generating = false
                        this.textBtnPrint = 'GERAR'                    
                    }
                }).catch(showError)
            }
        },
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval)
            this.$_loadingTimeInterval = null
        },
        startLoading() {
            this.loading = true
            this.loadingTime = 0
        },
        loadCostCenters() {
            const url = `${baseApiUrl}/costCenter`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.costCenters.push({ value: r.name,  text: r.name })                        
                }
            }).catch(showError)                        
        }        
    },
    created() {
      this.$_loadingTimeInterval = null
    },
    mounted() {
        this.loadCostCenters()
    }
}
</script>

<style>

</style>