<template>
  <div class="extract-admin">
    <PageTitle icon="fa fa-money" main="Lançamento" sub />
    <b-form>
      <input id="extract-id" type="hidden" v-model="extract.id" />
      <b-row>
        <b-col md="12" sm="12">
          <b-card bg-variant="default" header="Dados Gerais" header-bg-variant="dark" header-text-variant="white">
            <b-row>
              <b-col md="2" sm="12">
                <b-form-group label="Data:" label-for="extract-date">
                  <b-form-input id="extract-date" type="date" v-model="extract.date"/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Tipo:" label-for="extract-type">
                  <b-form-select id="extract-type" v-model="extract.type" :options="types"></b-form-select>
                </b-form-group>
              </b-col>                
              <b-col md="2" sm="12">
                <b-form-group label="Centro de Custo:" label-for="extract-costCenter">
                  <b-form-select id="extract-costCenter" v-model="extract.costCenter" :options="costCenters"></b-form-select>
                </b-form-group>
              </b-col>                     
              <b-col md="2" sm="12">
                <b-form-group label="Conta Financeira:" label-for="extract-account">
                  <b-form-select id="extract-account" v-model="extract.account" :options="accounts"></b-form-select>
                </b-form-group>
              </b-col>        
              <b-col md="2" sm="12">
                <b-form-group label="Forma Pagamento:" label-for="extract-account">
                  <b-form-select id="extract-formPayment" v-model="extract.formPayment" :options="formPayments"></b-form-select>
                </b-form-group>
              </b-col>                          
           </b-row>
           <b-row>
              <b-col md="2" sm="12">
                <b-form-group label="Valor:" label-for="extract-value">
                  <b-form-input id="extract-value" type="number" v-model="extract.value"/>
                </b-form-group>
              </b-col>                    
              <b-col md="8" sm="12">
                <b-form-group label="Histórico:" label-for="extract-historic">
                  <b-form-input id="extract-historic" type="text" v-model="extract.historic"/>
                </b-form-group>
              </b-col>                                  
           </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-button v-if="!extract._id" variant="success" @click="save" class="mt-3">SALVAR</b-button>
    <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>
  </div>
</template>
<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"

export default {
  name: "ExtractPage",
  components: { PageTitle },
  data: function () {
    return {
        extract: {},
        costCenters: [],
        costCenter: null,
        accounts: [],
        account: null,
        types: [
            { value: 'DESPESA', text: 'DESPESA' },
            { value: 'RECEITA', text: 'RECEITA' },
        ],
        type: null,
        formPayments: [
            { value: '0', text: 'DINHEIRO' },
            { value: '1', text: 'CARTÃO' },
            { value: '3', text: 'PIX' },
            { value: '4', text: 'BOLETO' }
        ],
        formPayment: null,
        currentPage: 1,
        perPage: 10,   
        ftext: '',        
    }
  },
  methods: {
    save() {
        this.extract.value = Number(this.extract.value).toFixed(2)
        this.extract.module = '0'

        const method = this.extract._id ? 'put' : 'post'
        const id = this.extract._id ? `/${this.extract._id}` : ''        

        axios[method](`${baseApiUrl}/extract${id}`, this.extract)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            })  
            .catch(showError)
    },
    cancel() {
        this.$router.go(-1)
    },
    loadAccounts() {
        const url = `${baseApiUrl}/account`
        axios.get(url).then(res => {
            for (let d of res.data) {
                this.accounts.push({
                    value: { _id: d._id, name: d.name }, text: d.name
                })
            }
        }).catch(showError)                              
    },
    loadCostCenters() {
        const url = `${baseApiUrl}/costCenter`
        axios.get(url).then(res => {
            for (let d of res.data) {
                this.costCenters.push({
                    value: { _id: d._id, name: d.name }, text: d.name
                })
            }
        }).catch(showError)                              
    }
  },
  mounted() {
      this.loadAccounts()
      this.loadCostCenters()

      if (this.$route.params.extractSelected) {        
        this.extract = this.$route.params.extractSelected      
        this.account = this.extract.account
        this.costCenter = this.extract.costCenter

        if (this.extract.formPayment) {
          this.formPayment = this.extract.formPayment
        }

        document.getElementById('extract-costCenter').disabled = true            
        document.getElementById('extract-account').disabled = true            
        document.getElementById('extract-date').disabled = true
        document.getElementById('extract-type').disabled = true
        document.getElementById('extract-formPayment').disabled = true
        document.getElementById('extract-historic').disabled = true
        document.getElementById('extract-value').disabled = true               
      } else {
        this.extract = {
            value: Number(0.00).toFixed(2)
        }
      }     
  }
}
</script>

<style>
</style>