<template>
  <div class="purchases-pages">
    <PageTitle icon="fa fa-shopping-cart" main="Compras" sub />
    <div class="purchases-pages-tabs">        
        <b-button size="md" variant="success" @click="newPurchases()">
            NOVO
        </b-button>      
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="purchases" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="info" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Detalhes'">
                    <i class="fa fa-info"></i>
                </b-button>
                <b-button class="ml-2" size="sm" variant="danger" @click="deletePurchase(row.item)" v-b-popover.hover.top="'Excluir'">
                    <i class="fa fa-trash"></i>
                </b-button>
            </template>
        </b-table>        

        <div v-if="purchases.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                   

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
    </div>
  </div> 
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: 'Purchases',
    components: { PageTitle },
    data: function() {
      return {
        ftext: '',
        purchases: [],
        purchaseSelected: {},
        currentPage: 1,
        perPage: 10,         
        fields: [                 
            { key: 'provider.name', label: 'Fornecedor', sortable: true },
            { key: 'date2', label: 'Data', sortable: true },
            { key: 'dueDate', label: 'Vencimento', sortable: true },
            { key: 'stockCenter.name', label: 'Centro de Estoque', sortable: true },
            { key: 'taxes', label: 'Impostos (R$)', sortable: true },
            { key: 'shipping', label: 'Frete (R$)', sortable: true },
            { key: 'discount', label: 'Desconto (R$)', sortable: true },
            { key: 'value', label: 'Valor Total (R$)', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],      
      }
    },
    computed: {
      rows() {
        return this.purchases.length
      }
    },      
    methods: {
        newPurchases() {
          this.$router.push({name: 'purchasePage', params: { },})          
        },
        onRowSelected(itemSelected) {
          let purchaseSelected = {    
              _id: itemSelected._id,
              date: itemSelected.date,
              provider: itemSelected.provider,
              costCenter: itemSelected.costCenter,
              stockCenter: itemSelected.stockCenter,
              products: itemSelected.products,
              account: itemSelected.account,
              value: itemSelected.value,
              shipping: itemSelected.shipping,
              discount: itemSelected.discount,
              taxes: itemSelected.taxes,
              parcel: itemSelected.parcel,
              dueDate: itemSelected.dueDate
          }              

          this.$router.push({name: 'purchasePage', params: { purchaseSelected },})            
        },
        deletePurchase(itemSelected) {
          this.$bvModal.msgBoxConfirm('Deseja remover a compra?', {
              title: 'Confirmação',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Sim',
              cancelTitle: 'Não',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
          }).then(value => {            
              if (value) {
                axios.post(`${baseApiUrl}/purchase/delete`, itemSelected).then(() => {
                  this.$toasted.global.defaultSuccess()
                  this.loadPurchases()
                }).catch(showError)
              }
          }).catch()
        },
        loadPurchases() {
            const url = `${baseApiUrl}/purchase`
            axios.get(url).then(res => {
                this.purchases = res.data            

                Moment.locale('pt-br')

                for (let p of this.purchases) {
                  p.date2 = Moment(p.date).format('L')
                  p.taxes = Number(p.taxes).toFixed(2)
                  p.shipping = Number(p.shipping).toFixed(2)
                  p.discount = Number(p.discount).toFixed(2)
                  p.value = Number(p.value).toFixed(2)
                }
            }).catch(showError)                      
        }    
    },
    mounted() {
        this.loadPurchases()
    }      
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>