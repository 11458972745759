<template>
    <div class="provider-admin">
        <PageTitle icon="fa fa-user" main="Cadastro de Fornecedores" sub />                  
        <b-form>            
            <input id="provider-id" type="hidden" v-model="provider.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="provider-name">
                            <b-form-input id="provider-name" type="text" 
                                v-model="provider.name" required                       
                                placeholder="Informe o Nome do Fornecedor..." />
                        </b-form-group>         
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CNPJ:" label-for="provider-cnpj">
                                    <b-form-input id="provider-cnpj" type="number" 
                                        v-model="provider.cnpj" required
                                        placeholder="Informe o CNPJ do Fornecedor..." />
                                </b-form-group> 
                                <b-form-group label="E-mail:" label-for="provider-email">
                                    <b-form-input id="provider-email" type="text"
                                        v-model="provider.email" 
                                        placeholder="Informe o E-mail do Fornecedor..." />
                                </b-form-group>                                     
                            </b-col>
                            <b-col>
                                <b-form-group label="Fone:" label-for="provider-phone">
                                    <b-form-input id="provider-phone" type="number"
                                        v-model="provider.phone" 
                                        placeholder="Informe o Fone do Fornecedor..." />
                                </b-form-group>                                 
                            </b-col>        
                        </b-row>
                    </b-card>
                </b-col>           
            </b-row>
        </b-form>
            
        <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: "ProviderPage",
    components: {PageTitle},
    data: function() {
      return { 
        provider: {},
      }    
    },
    methods: {          
        save() {
            this.provider.name = this.provider.name.toUpperCase()

            const method = this.provider._id ? 'put' : 'post'
            const id = this.provider._id ? `/${this.provider._id}` : ''        

            axios[method](`${baseApiUrl}/provider${id}`, this.provider)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                })  
                .catch(showError)
        },  
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        if (this.$route.params.providerSelected) {
            this.provider = this.$route.params.providerSelected            
        }
    }
}     
</script>

<style>

</style>